import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import "./App.css";
import Membership from "./component/Membership";

import Contact from "./component/Contact";

import Login from "./component/Login/index";
import Navbars from "./component/Navbars";
import Messages from "./component/Messages/index";
import Editphoto from "./component/Editphoto";

import Profileedit from "./component/Profileedit";

import Slidermatches from "./component/Slidermatches";
import Profiledetails from "./component/Profiledetails";

import Upgrade from "./component/Upgrade";
import Slidermobile from "./component/Slidermobile";
import Addphoto from "./component/Addphoto";
import Packeges from "./component/Packeges";
import Searchresult from "./component/Searchresult";
import Singleprofile from "./component/Singleprofile";
import Morematches from "./component/Morematches";

import Searchloginafter from "./component/Searchloginafter";

import { Pagination } from "react-bootstrap";
import NotFound from "./component/NotFound";
import ChatPopup from "./component/ChatPopup";
import Chat from "./component/Chat";
import Messageaccepted from "./component/Messageaccepted";

import Footerinner from "./component/Footerinner";

import MyHome from "./component/Home/MyHome";
import EditProfile from "./component/EditProfile/EditProfile";
import "react-toastify/dist/ReactToastify.css";
import Registration from "./component/Registration";
import Matches from "./component/Matches/index";
import Interations from "./component/Interations";
import NewProfileDetails from "./component/ProfileDetails/Index";
import SuccessStory from "./component/SuccessStory/Index";
import Settings from "./component/Settings/Index";
import SearchProfiles from "./component/SearchProfiles";
import Notifications from "./component/Notifications/Index";
import Mobileslider from "./component/Mobileslider";
 
function App() {
  return (
    <>
      <Router>
        <main>
          <Routes>
            <Route path="/" element={<Membership />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/Navbars" element={<Navbars />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/my-home" element={<MyHome />} />
            <Route path="/matches" element={<Matches />} />
            <Route path="/Editphoto" element={<Editphoto />} />
            <Route path="/Profileedit" element={<Profileedit />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/Slidermatches" element={<Slidermatches />} />
            <Route path="/Profiledetails" element={<Profiledetails />} />
            <Route path="/Upgrade" element={<Upgrade />} />
            <Route path="/Slidermobile" element={<Slidermobile />} />
            <Route path="/add-photo" element={<Addphoto />} />
            <Route path="/Packeges" element={<Packeges />} />
            <Route path="/Searchresult" element={<Searchresult />} />

            <Route
              path="/Profiledetails/:userId"
              element={<Profiledetails />}
            />
            <Route
              path="/profile-details/:userId"
              element={<NewProfileDetails />}
            />
            
            <Route path="/Pagination" element={<Pagination />} />
            <Route path="*" element={<NotFound />} />

            <Route path="/Messageaccepted" element={<Messageaccepted />} />

            <Route path="/Footerinner" element={<Footerinner />} />
            <Route path="/interactions" element={<Interations />} />
            <Route path="/success-stories" element={<SuccessStory />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/search" element={<SearchProfiles />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/Mobilesslider" element={<Mobileslider />} />

   {/*      <Route path="/ChatPopup" element={<ChatPopup />} />
            <Route path="/Chat" element={<Chat />} />
            <Route path="/Singleprofile" element={<Singleprofile />} />
            <Route path="/Morematches" element={<Morematches />} />
            <Route path="/Searchloginafter" element={<Searchloginafter />} /> */}
          </Routes>
        </main>
      </Router>
    </>
  );
}

export default App;

import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";

const PreferenceMatches = ({
  userData,
  listHeights,
  occupationList,
  stateAndDistricts,
  listStars,
}) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [preferenceMatches, setPreferenceMatches] = useState({
    min_age: 18,
    max_age: 18,
    min_height: "",
    max_height: "",
    marital_status: "",
    drinking_habit: "",
    eating_habit: "",
    physical_status: "",
    smoking_habit: "",
    professional_status: "",
  });
  const [isEditingPreferenceMatches, setIsEditingPreferenceMatches] =
    useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedStars, setSelectedStars] = useState([]);

  useEffect(() => {
    setPreferenceMatches({
      min_age: userData?.partner_min_age
        ? Number(userData?.partner_min_age)
        : 18,
      max_age: userData?.partner_max_age
        ? Number(userData?.partner_max_age)
        : 18,
      min_height: userData?.partner_min_height,
      max_height: userData?.partner_max_height,
      marital_status: userData?.partner_marital_status,
      drinking_habit: userData?.partner_drinking_habit,
      eating_habit: userData?.partner_eating_habit,
      physical_status: userData?.partner_physical_status,
      smoking_habit: userData?.partner_smoking_habit,
      professional_status: userData?.partner_professional_status,
    });
    if (stateAndDistricts.length > 0) {
      setLocations([...stateAndDistricts[0]?.districts]);
    }
    if (userData?.partner_location && userData?.partner_location.length > 0) {
      const selectd = userData?.partner_location.map((item) => item.id);
      setSelectedLocations(selectd);
    }
    if (userData?.partner_star && userData?.partner_star.length > 0) {
      const selectd = userData?.partner_star.map((item) => item.id);
      setSelectedStars(selectd);
    }
  }, [userData]);

  const handleEditClickPartner = () => {
    setIsEditingPreferenceMatches(true);
  };

  const handleSaveClickPartner = async () => {
    const payload = {
      age_from: preferenceMatches?.min_age,
      age_to: preferenceMatches?.max_age,
      height_from: preferenceMatches?.min_height,
      height_to: preferenceMatches?.max_height,
      matrial_status: preferenceMatches?.marital_status,
      eating_habit: preferenceMatches?.eating_habit,
      drinking_habit: preferenceMatches?.drinking_habit,
      smoking_habit: preferenceMatches?.smoking_habit,
      physical_status: preferenceMatches?.physical_status,
      professional_details: preferenceMatches?.professional_status,
      selectedLocation: [...selectedLocations],
      selectedStar: [...selectedStars],
    };
    await api.updatePreferMatches(payload, token).then((data) => {
      if (data.status) {
        setIsEditingPreferenceMatches(false);
      } else if (data.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    });
  };

  const handleSelectLocation = (value) => {
    const findLocation = selectedLocations.find((item) => item === value);
    let newLocation = [];
    if (findLocation) {
      const filteredLocation = selectedLocations.filter(
        (location) => location !== value
      );
      newLocation = [...filteredLocation];
    } else {
      newLocation = [...selectedLocations, value];
    }
    setSelectedLocations([...newLocation]);
  };

  const handleSelectStar = (value) => {
    const findStar = selectedStars.find((item) => item === value);
    let newStar = [];
    if (findStar) {
      const filteredStar = selectedStars.filter((star) => star !== value);
      newStar = [...filteredStar];
    } else {
      newStar = [...selectedStars, value];
    }
    setSelectedStars([...newStar]);
  };

  return (
    <>
      <p className="partner-preference-profileedit">Partner Preferences</p>
      <div className="importentdetails-editprofile">
        <MDBRow>
          <div className="user-description">
            <p className="profile-description">Basic Details</p>
            <div className="editing-religious-save">
              {isEditingPreferenceMatches ? (
                <button onClick={handleSaveClickPartner}>Save</button>
              ) : (
                <button onClick={handleEditClickPartner}>
                  <MDBIcon far icon="edit" /> Edit
                </button>
              )}
            </div>
          </div>
          <MDBCol size="6" sm="6" className="partner-profileedit">
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Age From
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="age"
                    name="age"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.min_age}
                    onChange={(e) => {
                      const selectedAgeFrom = parseInt(e.target.value);

                      setPreferenceMatches({
                        ...preferenceMatches,
                        min_age: selectedAgeFrom,
                        max_age: selectedAgeFrom + 1, // Age To is set to Age From + 1
                      });
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    {Array.from({ length: 83 }, (_, index) => (
                      <option key={index} value={18 + index}>
                        {18 + index} years
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Age From
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.min_age || "Not defined"}
                </div>
              </div>
            )}
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Age To
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="age"
                    name="age"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.max_age}
                    onChange={(e) => {
                      const selectedAgeTo = parseInt(e.target.value);

                      setPreferenceMatches({
                        ...preferenceMatches,
                        max_age: selectedAgeTo,
                      });
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    {Array.from(
                      { length: 83 - preferenceMatches?.min_age },
                      (_, index) => (
                        <option
                          key={index}
                          value={preferenceMatches?.min_age + 1 + index}
                        >
                          {preferenceMatches?.min_age + 1 + index} years
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Age To
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.max_age || "Not defined"}
                </div>
              </div>
            )}

            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Marital Status
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="maritalStatus"
                    name="maritalStatus"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.marital_status}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        marital_status: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="Marital Status">
                      {" "}
                      Select
                    </option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Never Married">Never Married</option>
                    <option value="widowed">widowed</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Seperated">Seperated</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Marital Status
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.marital_status || "Not defined"}
                </div>
              </div>
            )}

            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Eating Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="eating"
                    name="eating"
                    className="SelectBoxs-searchpage-profileedit"
                    style={{ width: 350 }}
                    value={preferenceMatches?.eating_habit}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        eating_habit: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      {" "}
                      Select
                    </option>

                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Non vegetarian">Non vegetarian</option>
                    <option value="Eggetarian">Eggetarian</option>
                    <option value="Does not matter">Does not matter</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Eating Habits
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.eating_habit || "Not defined"}
                </div>
              </div>
            )}
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Smoking Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="smoke"
                    name="smoke"
                    className="SelectBoxs-searchpage-profileedit"
                    style={{ width: 350 }}
                    value={preferenceMatches?.smoking_habit}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        smoking_habit: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      {" "}
                      Select
                    </option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Never smokes">Never smokes</option>
                    <option value="Smoke occasionally">
                      Smoke occasionally
                    </option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Smoking Habits
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.smoking_habit || "Not defined"}
                </div>
              </div>
            )}
          </MDBCol>
          <MDBCol size="6" sm="6" className="partner-profileedit">
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Height From
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="height_from"
                    name="height_from"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.min_height}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        min_height: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    {listHeights.map((height) => (
                      <option key={height.id} value={`${height.height_cm} cm `}>
                        {`${height.height_cm} cm / ${height.height_feet}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Height From
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches.min_height || "Not defined"}
                </div>
              </div>
            )}
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Height To
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="height_to"
                    name="height_to"
                    className="SelectBoxs-searchpage-profileedit"
                    style={{ width: 350 }}
                    value={preferenceMatches?.max_height} // Initialize with basicDetails.height_id
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        max_height: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    {listHeights.map((height) => (
                      <option key={height.id} value={`${height.height_cm} cm`}>
                        {`${height.height_cm} cm / ${height.height_feet}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Height To
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.max_height || "Not defined"}
                </div>
              </div>
            )}

            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Driking Habits
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="drink"
                    name="drink"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.drinking_habit}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        drinking_habit: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select</option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Drinks occasionally">Drinks occasionally</option>
                    <option value="Never drinks">Never drinks</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Driking Habits
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.drinking_habit || "Not defined"}
                </div>
              </div>
            )}
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Physical Status
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="physicalStatus"
                    name="physicalStatus"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.physical_status}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        physical_status: e.target.value,
                      });
                    }}
                  >
                    <option value="Physical Status">Select</option>
                    <option value="Does not matter">Does not matter</option>
                    <option value="Normal">Normal</option>
                    <option value="Slim">Slim</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Physical Status
                </div>
                <div style={{ flex: 1 }}>
                  :{""} {preferenceMatches?.physical_status || "Not defined"}
                </div>
              </div>
            )}
            {isEditingPreferenceMatches ? (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="">
                  Professional Status
                </div>
                :
                <div className="basic-profiles" style={{ flex: 1 }}>
                  <select
                    id="partner_professional_Status"
                    name="partner_professional_Status"
                    className="SelectBoxs-searchpage-profileedit"
                    value={preferenceMatches?.professional_status}
                    onChange={(e) => {
                      setPreferenceMatches({
                        ...preferenceMatches,
                        professional_status: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      Select
                    </option>
                    {occupationList.map((occupation) => (
                      <option
                        key={occupation.id}
                        value={occupation?.occupation}
                      >
                        {occupation.occupation}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div className="annual-income" style={{ display: "flex" }}>
                <div style={{ flex: 1 }} className="full-bolder">
                  Professional Status
                </div>
                <div style={{ flex: 1 }}>
                  :{""}{" "}
                  {preferenceMatches?.professional_status || "Not defined"}
                </div>
              </div>
            )}
          </MDBCol>
          {selectedLocations.length > 0 || isEditingPreferenceMatches ? (
            <p className="profile-description">Locations</p>
          ) : (
            ""
          )}
          <ul className="ks-cboxtags">
            {isEditingPreferenceMatches ? (
              <>
                {locations.map((location, index) => {
                  const findLocation = selectedLocations.find(
                    (item) => item === location.dist_id
                  );
                  let isSelected = false;
                  if (findLocation) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label
                        htmlFor="checkboxOne"
                        onClick={() => handleSelectLocation(location?.dist_id)}
                      >
                        {location?.district}
                      </label>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {selectedLocations.map((location, index) => {
                  const findLocation = locations.find(
                    (item) => item.dist_id === location
                  );
                  let isSelected = false;
                  if (findLocation) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label htmlFor="checkboxOne">
                        {findLocation?.district}
                      </label>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
          {selectedStars.length > 0 || isEditingPreferenceMatches ? (
            <p className="profile-description">Stars</p>
          ) : (
            ""
          )}
          <ul className="ks-cboxtags">
            {isEditingPreferenceMatches ? (
              <>
                {listStars.map((star, index) => {
                  const findStar = selectedStars.find(
                    (item) => item === star.id
                  );
                  let isSelected = false;
                  if (findStar) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label
                        htmlFor="checkboxOne"
                        onClick={() => handleSelectStar(star?.id)}
                      >
                        {star?.star}
                      </label>
                    </li>
                  );
                })}
              </>
            ) : (
              <>
                {selectedStars.map((star, index) => {
                  const findStar = listStars.find(
                    (item) => item.id === star
                  );
                  let isSelected = false;
                  if (findStar) {
                    isSelected = true;
                  }
                  return (
                    <li key={index}>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => {}}
                      />
                      <label htmlFor="checkboxOne">
                        {findStar?.star}
                      </label>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
        </MDBRow>
      </div>
    </>
  );
};

export default PreferenceMatches;

import Nva from './Nva'
import Footer from './Footer'
import '../style/searchprofile.css'
import { MDBCol, MDBContainer, MDBIcon, MDBListGroup, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Form, Link, useNavigate } from 'react-router-dom'
import ReactDOM from "react-dom";
import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import axios
import instance from '../axios'


function Searchresult() {
  const minAge = 21;
  const maxAge = 60;

  const ageOptions = [];
  ageOptions.push(<option key="" value="" disabled selected> Age</option>);
  for (let age = minAge; age <= maxAge; age++) {
    ageOptions.push(<option key={age} value={age}>{age}</option>);
  }


  //API for profile id search//

  const [id, setId] = useState('');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const navigate = useNavigate();
  const handleSearch = async () => {
    try {
      console.log(id);
      const response = await instance.get('/api/pulayakalyanam/searchID', {
        params: { id: id },
      });
      console.log(response);
      if (response.status === 200) {
        setData(response.data);
        setError(null);
        navigate('/Singleprofile', { state: { data: response.data } });

      } else {
        throw new Error('Network response was not ok');
      }
    } catch (err) {
      setShowNotFoundMessage(true);
      setError('user is not found');
      setData(null);
    }
  };

  const [datavalue, setDataValue] = useState(null);
  const [search_valueNotFoundMessage, setSearch_valueNotFoundMessage] = useState(false);

  const handleSearchValue = async () => {
    const ageFrom = document.getElementById('ageFrom').value;
    const ageTo = document.getElementById('ageTo').value;
    const heightFrom = document.getElementById('heightFrom').value;
    const heightTo = document.getElementById('heightTo').value;
    const matrial_status = document.getElementById('maritalStatus').value;
    const star = document.getElementById('star').value;
    const occupation = document.getElementById('occupation').value;
    const city = document.getElementById('city').value;
    const employed_in = document.getElementById('employedIn').value;
    const annualIncome = document.getElementById('annualIncome').value;

    try {
      const response = await instance.post('/api/pulayakalyanam/searchValue', {
        ageFrom,
        ageTo,
        heightFrom,
        heightTo,
        matrial_status,
        star,
        occupation,
        city,
        employed_in,
        annualIncome,
      });

      console.log(response);

      if (response.status === 200) {
        setDataValue(response.data);
        console.log(datavalue);
        setError(null);
        navigate('/Morematches', { state: { data: response.data.data } });
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearch_valueNotFoundMessage(true)
    }
  };




  // view height
  const [height, setheigt] = useState([]);
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/view_height')
      .then(response => {
        setheigt(response.data);
        console.log('stsetheigtar : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching Occupation:', error);
      });
  }, []);



  // view star
  const [star, setStar] = useState([]);
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/view_star')
      .then(response => {
        setStar(response.data);
        console.log('star : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching Occupation:', error);
      });
  }, []);


  // view city
  const [city, setCity] = useState([]);
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/show_district_withoutState')
      .then(response => {
        setCity(response.data);
        console.log('city : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching Occupation:', error);
      });
  }, []);

  // view employeed in
  const [employeedIn, setEmployeedIn] = useState([]);
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/view_employeed_in')
      .then(response => {
        setEmployeedIn(response.data);
        console.log('city : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching Occupation:', error);
      });
  }, []);


  // view occupation
  const [occupation, setOccupation] = useState([]);
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/show_occupatoin')
      .then(response => {
        setOccupation(response.data);
        console.log('occupation : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching Occupation:', error);
      });
  }, []);

  // view annualIncome
  const [annualincome, setAnnualIncome] = useState([]);
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/view_annual_income')
      .then(response => {
        setAnnualIncome(response.data);
        console.log('setAnnualIncome : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching Occupation:', error);
      });
  }, []);


  return (
    <div className='searchhome'>
      <Nva />
      <div className='registerbackground'>
        <MDBContainer className="register mt-5 ">

          <div className='registerform'>

            <div className="rgtr">
              <div className="bannr">
                <img src='../Images/panchami_banner.jpg' className='img-fluid ' alt='...' style={{ width: 100 }} />
              </div>
            </div>



            <Container className='search-border'>

              <div className='insertform'>
                <div className='searchprofile'>
                  <h3>Search Profile</h3>
                </div>
                <div className="search-container">
                  <p className="search-by-id">Search by ID</p>
                  <div className="search-input-container">
                    <div className="search-input">
                      <div className="search-box">
                        <input
                          type='text'
                          id="search-textarea"
                          placeholder="Search"
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                        ></input>
                        <button
                          type="button"
                          className="search-button search-icon-first"
                          onClick={handleSearch}
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>

                    {/*  user not found message */}
                    {showNotFoundMessage && (
                      <div className="user-not-found">
                        {error || 'User not found' }
                      </div>
                    )}


                  </div>
                </div>

                <div className='search-form-result'>

                  <MDBRow>
                    <MDBCol size='6' sm='6' className='searchprofile-result' >
                      <form>

                        <select id="ageFrom" name="ageFrom" className='SelectBoxs-searchpage-result ' style={{ width: 480 }}>
                          <option key="" value="">Age (From)</option>
                          {Array.from({ length: 83 }, (_, index) => (
                            <option key={index} value={18 + index}>
                              {18 + index} years
                            </option>
                          ))}
                        </select>



                      </form><br></br>
                      <form>

                        <select id="ageTo" name="ageTo" className='SelectBoxs-searchpage-result ' style={{ width: 480 }}>
                          <option key="" value="">Age (To)</option>
                          {Array.from({ length: 83 }, (_, index) => (
                            <option key={index} value={18 + index}>
                              {18 + index} years
                            </option>
                          ))}
                        </select>



                      </form><br></br>

                      <form>

                        <select id="maritalStatus" name="maritalStatus"
                          className='SelectBoxs-searchpage-result' style={{ width: 480 }}>

                          <option key="" value=""  >Marital Status:</option>
                          <option key="" value="Never Married" >Never Married</option>
                          <option key="" value="widowed" >widowed</option>
                          <option key="" value="Divorced" >Divorced</option>
                          <option key="" value="Seperated" >Seperated</option>

                        </select>
                      </form><br></br>


                      <form>
                        {/*  //star */}
                        <select id="star" name="star" className='SelectBoxs-searchpage-result' style={{ width: 480 }}>
                          <option key="" value="" >Star</option>

                          {star.map(star => (
                            <option key={star.pn_sid} value={star.pn_sid}>
                              {star.c_star_name} {/* Assuming 'name' is the property in the star object */}
                            </option>
                          ))}
                        </select>
                      </form><br></br>
                      <form>
                        {/* occupation */}
                        <select id="occupation" name="occupation"
                          className='SelectBoxs-searchpage-result' style={{ width: 480 }}>

                          <option key="" value=""  >Occupation</option>


                          {occupation.map(occupation => (
                            <option key={occupation.occid} value={occupation.occid}>
                              {occupation.occupation}
                            </option>
                          ))}

                        </select>
                      </form><br></br>

                    </MDBCol>
                    <MDBCol size='6' sm='6' className='searchprofile-result'>
                      <form>
                        {/* height */}
                        <select id="heightFrom" name="heightFrom" className='SelectBoxs-searchpage-result ' placeholder='Height:' style={{ width: 480 }}>
                          <option key="" value="" > Height(From)</option>
                          {height.map(height => (
                            <option key={height.n_slno} value={height.n_slno}>
                              {height.n_cm} cm
                            </option>
                          ))}
                        </select>

                      </form><br></br>
                      <form>

                        <select id="heightTo" name="heightTo" className='SelectBoxs-searchpage-result '
                          placeholder='Height:' style={{ width: 480 }}>

                          <option key="" value="" > Height(To)</option>
                          {height.map(height => (
                            <option key={height.n_slno} value={height.n_slno}>
                              {height.n_cm} cm
                            </option>
                          ))}
                        </select>

                      </form><br></br>
                      <form>
                        {/* city */}
                        <select id="city" name="city"
                          className='SelectBoxs-searchpage-result' style={{ width: 480 }}>

                          <option key="" value="" >City</option>


                          {city.map(city => (
                            <option key={city.n_dist_id} value={city.n_dist_id}>
                              {city.c_district} {/* Assuming 'name' is the property in the star object */}
                            </option>
                          ))}
                        </select>
                      </form><br></br>



                      <form>

                        <select id="employedIn" name="employedIn"
                          className='SelectBoxs-searchpage-result' style={{ width: 480 }}>

                          <option key="" value=""  >Employeed in</option>


                          {employeedIn.map(employeedIn => (
                            <option key={employeedIn.emp_id} value={employeedIn.emp_id}>
                              {employeedIn.employed_in}
                            </option>
                          ))}

                        </select>
                      </form><br></br>

                      <form>

                        <select id="annualIncome" name="annualIncome"
                          className='SelectBoxs-searchpage-result' style={{ width: 480 }}>

                          <option key="" value="" >Annual Income</option>

                          {annualincome.map(annualincome => (
                            <option key={annualincome.id} value={annualincome.id}>
                              {annualincome.annual_income}
                            </option>
                          ))}

                        </select>
                      </form><br></br>

                    </MDBCol>


                    <Button className='searchButtons-search-result' type="submit"
                      onClick={handleSearchValue}>
                      Search
                    </Button>
                    {/*  user not found message */}
                    {search_valueNotFoundMessage && (
                      <div className="user-not-found">
                        {error || 'User not found'}
                      </div>
                    )}
                  </MDBRow>



                </div>



              </div>


            </Container>
          </div>
        </MDBContainer>
      </div>
      <Footer />


    </div>
  )
}

export default Searchresult
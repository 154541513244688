import axios1 from "axios";

const BASEURL = "https://panchamimatrimony.com/api/" 
/* const BASEURL = "https://panchami.gipra.in/api/"  */
/* const BASEURL = "http://127.0.0.1:4000/api/" */
export const constants = {
  baseURL: BASEURL,
};
export const axios = axios1.create({
  baseURL: "",
  timeout: 30000,
  headers: { "Content-Type": "application/json",
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*', },
});
axios.interceptors.request.use(
  async function (_config) {
    return _config;
  },
  function (err) {
    console.log("error due to  no internet", err);
    return Promise.reject(err);
  }
);

axios.defaults.timeout = 30000;
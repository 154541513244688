import "../../style/searchprofile.css";

import { MDBCol } from "mdb-react-ui-kit";
import { Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import instance from "../../axios";
import { useEffect, useState } from "react";
import api from "../../Api/GeneralApi";
import SearchIDResult from "./SearchIDResult";
import SearchValueResult from "./SearchValueResult";
import { toast } from "react-toastify";

function Search_container() {
  const token = localStorage.getItem("authToken");

  const [showSearchResultValue, setShowSearchResultValue] = useState(false);

  const [user, setUser] = useState(false);
  const [searchList, setSearchList] = useState([]);

  const [listError, setListError] = useState("");

  const [searchIdError, setSearchIderror] = useState("");
  const [id, setId] = useState("");
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchData, setSearchData] = useState(null);
  const [listFromAge, setListFromAge] = useState([]);
  const [listToAge, setListToAge] = useState([]);
  const [height, setHeight] = useState([]);
  const [listHeightFrom, setListHeightFrom] = useState([]);
  const [listHeightTo, setListHeightTo] = useState([]);
  const [stars, setStar] = useState([]);
  const [city, setCity] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [annualIncome, setAnnualIncome] = useState([]);
  const [employeedIn, setEmployeedIn] = useState([]);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openStar, setOpenStar] = useState(false);
  const [selectedStars, setSelectedStars] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    page: 1,
    limit: 10,
  });
  const [pagination, setPagination] = useState({});
  const [searchValue, setSearchValue] = useState({
    ageFrom: "",
    ageTo: "",
    heightFrom: "",
    heightTo: "",
    matrial_status: "",
    star: "",
    occupation: "",
    city: "",
    employed_in: "",
    annualIncome: "",
  });

  useEffect(() => {
    fetchUser();
    const newArray = [];
    for (let i = 18; i <= 60; i++) {
      newArray.push(i);
    }
    setListFromAge(newArray);
    setListToAge(newArray);
  }, []);

  useEffect(() => {
    if (isSearchClicked) {
      const element = document.getElementById("search-value-button");
      element.scrollIntoView({ behavior: "smooth" });
      handleSearchValue();
    }
  }, [pageDetails]);

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setUser(false);
      } else {
        setUser(true);
      }

      const response = await instance.get("api/search-list");

      setSearchList(response.data);
      setHeight(response.data.data.heights);
      setListHeightFrom(response.data.data.heights);
      setListHeightTo(response.data.data.heights);
      setStar(response.data.data.star);
      setAnnualIncome(response.data.data.annual_income);
      setOccupation(response.data.data.occupation);
      setEmployeedIn(response.data.data.employed_in);
      setCity(response.data.data.stateDistrict[0].districts);
    } catch (error) {
      setListError(error);
    }
  };

  const handleSearchId = async () => {
    await api
      .getSearchById(id, token)
      .then((response) => {
        if (response.data.status === true) {
          const responseData = response.data;
          if (responseData.status === true && responseData.statusCode === 200) {
            setSearchData(responseData.data);
            setShowSearchResult(true);
            setShowNotFoundMessage(false);
          } else {
            setSearchIderror("user not found");
          }
        } else {
          setShowNotFoundMessage(true);
          setSearchData(null);
        }
      })

      .catch((error) => {
        toast.error("Sorry, something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const [searchDataValue, setSearchDataValue] = useState([]);
  const [search_valueNotFoundMessage, setSearch_valueNotFoundMessage] =
    useState("");

  const handleSearchValue = async () => {
    setIsSearchClicked(true);
    searchValue.page = pageDetails?.page;
    searchValue.pageSize = pageDetails?.limit;
    searchValue.city = selectedLocations.map((item) => item.dist_id);
    searchValue.star = selectedStars.map((item) => item.id)
    await api
      .getSearchByValue(searchValue, token)
      .then((response) => {
        if (response.status === true && response.statusCode === 200) {
          const data_api = response.data.users;
          if (data_api.length > 0) {
            setSearchDataValue(data_api);
            setShowSearchResultValue(true);
            setSearch_valueNotFoundMessage("");
            setPagination(response?.data?.pagination);
          } else {
            setSearchDataValue(data_api);
            setSearch_valueNotFoundMessage("User not found");
            setPagination({});
          }
        } else {
          setSearchDataValue("");
          const data_api = response.data.message;
          setSearch_valueNotFoundMessage(data_api);
        }
      })
      .catch((error) => {
        toast.error("Sorry, something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handlePagination = ({ selected }) => {
    const pageNumber = selected + 1;

    setPageDetails((prevValue) => ({
      ...prevValue,
      page: pageNumber,
    }));
  };

  const minHeight = 140;
  const maxHeight = 199;

  const heightOptions = [];
  heightOptions.push(
    <option key="" value="" disabled selected>
      Height:{" "}
    </option>
  );
  for (let height = minHeight; height <= maxHeight; height++) {
    heightOptions.push(
      <option key={height} value={height}>
        {height}
      </option>
    );
  }

  const handleAgefrom = (e) => {
    setSearchValue((prev) => ({
      ...prev,
      ageFrom: e.target.value,
      ageTo: "",
    }));
    let value = Number(e.target.value) + 1;
    const selectedIndex = listFromAge.indexOf(value);
    if (selectedIndex !== -1) {
      setListToAge(listFromAge.slice(selectedIndex));
    }
  };

  const handleAgeTo = (e) => {
    setSearchValue((prev) => ({
      ...prev,
      ageTo: e.target.value,
    }));
  };

  const handleHeightFromChange = (e) => {
    setSearchValue((prev) => ({
      ...prev,
      heightFrom: e.target.value,
      heightTo: "",
    }));
    const selectedIndex = listHeightFrom.findIndex(
      (item) => Number(item.height_cm) === Number(e.target.value)
    );
    if (selectedIndex !== -1) {
      setListHeightTo(listHeightFrom.slice(selectedIndex));
    }
  };

  const handleHeightToChange = (e) => {
    setSearchValue((prev) => ({
      ...prev,
      heightTo: e.target.value,
    }));
  };

  const handleDistrictChange = (district) => {
    if (openLocation === true) {
      setOpenLocation(false);
    }
    const findLocation = selectedLocations.find(
      (location) => location?.dist_id === district?.dist_id
    );
    if (!findLocation) {
      setSelectedLocations([...selectedLocations, district]);
    } else {
      const filterLocation = selectedLocations.filter(
        (location) => location?.dist_id !== district?.dist_id
      );
      setSelectedLocations([...filterLocation]);
    }
  };

  const handleStarChange = (star) => {
    if (openStar === true) {
      setOpenStar(false);
    }
    const findStar = selectedStars.find((item) => item?.id === star?.id);
    if (!findStar) {
      setSelectedStars([...selectedStars, star]);
    } else {
      const filterStar = selectedStars.filter((item) => item?.id !== star?.id);
      setSelectedStars([...filterStar]);
    }
  };

  return (
    <MDBCol size="6" sm="9" className="full-list-matches">
      <div className="searchprofile">
        <h4>Search Profile</h4>
      </div>
      <div className="search-container">
        <p className="search-by-id">Search by ID</p>
        <div className="search-input-container">
          <div className="search-input">
            <div className="search-box">
              <input
                type="text"
                id="search-textarea"
                placeholder="Search"
                value={id}
                onChange={(e) => setId(e.target.value)}
              ></input>
              <button
                type="submit"
                className="search-button search-icon-first"
                onClick={handleSearchId}
              >
                <i className="fas fa-search" onClick={handleSearchId}></i>
              </button>
            </div>

            {showSearchResult && <SearchIDResult data={searchData} />}
            {/*  user not found message */}
            {showNotFoundMessage && (
              <div className="user-not-found">{"User not found"}</div>
            )}
          </div>
        </div>
      </div>

      <div className="search-form">
        <form className="row mb-3">
          <div className="col">
            <p className="m-0">Age from</p>
          </div>
          <div className="col d-flex justify-content-end">
            <select
              name="ageFrom"
              className="search-select"
              value={searchValue?.ageFrom}
              onChange={handleAgefrom}
            >
              <option value="">Any</option>
              {listFromAge.map((listHeight, index) => (
                <option key={index + 1} value={listHeight}>
                  {listHeight} years
                </option>
              ))}
            </select>
          </div>
        </form>
        <form className="row mb-3">
          <div className="col">
            <p className="m-0">Age To</p>
          </div>
          <div className="col d-flex justify-content-end">
            <select
              name="ageTo"
              className="search-select"
              value={searchValue?.ageTo}
              onChange={handleAgeTo}
            >
              <option key="" value="">
                Any
              </option>
              {listToAge.map((listHeight, index) => (
                <option key={index} value={listHeight}>
                  {listHeight} years
                </option>
              ))}
            </select>
          </div>
        </form>

        {/* Height */}
        <form className="row mb-3">
          <div className="col">
            <p className="m-0">Height from</p>
          </div>
          <div className="col d-flex justify-content-end">
            <select
              id="heightFrom"
              name="heightFrom"
              className="search-select"
              value={searchValue?.heightFrom}
              onChange={handleHeightFromChange}
            >
              <option value="">Any</option>
              {listHeightFrom.map((item) => (
                <option key={item.id} value={item.height_cm}>
                  {item.height_feet}
                </option>
              ))}
            </select>
          </div>
        </form>
        <form className="row mb-3">
          <div className="col">
            <p className="m-0">Height To</p>
          </div>
          <div className="col d-flex justify-content-end">
            <select
              id="heightTo"
              name="heightTo"
              className="search-select"
              value={searchValue?.heightTo}
              onChange={handleHeightToChange}
            >
              <option value="">Any</option>
              {listHeightTo.map((item) => (
                <option key={item.id} value={item.height_cm}>
                  {item.height_feet}
                </option>
              ))}
            </select>
          </div>
        </form>
        {/* Matrial status */}
        <form className="row mb-3">
          <div className="col">
            <p className="m-0">Marital Status</p>
          </div>
          <div className="col d-flex justify-content-end">
            <select
              name="maritalStatus"
              className="search-select"
              value={searchValue?.matrial_status}
              onChange={(e) => {
                setSearchValue((prevValue) => ({
                  ...prevValue,
                  matrial_status: e.target.value,
                }));
              }}
            >
              <option value="">Any</option>
              <option value="Never Married">Never Married</option>
              <option value="widowed">widowed</option>
              <option value="Divorced">Divorced</option>
              <option value="Seperated">Seperated</option>
            </select>
          </div>
        </form>
        {/* Occupation */}
        <form className="row mb-3">
          <div className="col">
            <p className="m-0">Occupation</p>
          </div>
          <div className="col d-flex justify-content-end">
            <select
              id="occupation"
              name="occupation"
              className="search-select"
              value={searchValue?.occupation}
              onChange={(e) => {
                setSearchValue((prevValue) => ({
                  ...prevValue,
                  occupation: e.target.value,
                }));
              }}
            >
              <option key="" value="">
                Any
              </option>
              {occupation.map((occupation) => (
                <option key={occupation.id} value={occupation.id}>
                  {occupation.occupation}
                </option>
              ))}
            </select>
          </div>
        </form>
        {/* Employed In */}
        <form className="row mb-3">
          <div className="col">
            <p className="m-0">Employeed in</p>
          </div>
          <div className="col d-flex justify-content-end">
            <select
              id="employedIn"
              name="employedIn"
              className="search-select"
              value={searchValue?.employed_in}
              onChange={(e) => {
                setSearchValue((prevValue) => ({
                  ...prevValue,
                  employed_in: e.target.value,
                }));
              }}
            >
              <option key="" value="">
                Any
              </option>
              {employeedIn.map((employeedIn) => (
                <option key={employeedIn.id} value={employeedIn.id}>
                  {employeedIn.employed_in}
                </option>
              ))}
            </select>
          </div>
        </form>
        {/* Annual Inacome */}
        <form className="row mb-3">
          <div className="col">
            <p className="m-0">Annual Income</p>
          </div>
          <div className="col d-flex justify-content-end">
            <select
              id="annualIncome"
              name="annualIncome"
              className="search-select"
              value={searchValue?.annualIncome}
              onChange={(e) => {
                setSearchValue((prevValue) => ({
                  ...prevValue,
                  annualIncome: e.target.value,
                }));
              }}
            >
              <option key="" value="">
                Any
              </option>
              {annualIncome.map((incomeOption) => (
                <option key={incomeOption.id} value={incomeOption.id}>
                  {incomeOption.annual_income}
                </option>
              ))}
            </select>
          </div>
        </form>
        {/* Distric */}
        <form className="row mb-3">
          <div className="col">
            <p className="m-0">District</p>
          </div>
          <div className="col d-flex justify-content-end">
            <div className="hero">
              <div className="button-drop-container">
                <form className="button-group" style={{ margin: "0px" }}>
                  <div className="drop-button-wrap">
                    <input
                      id="db1"
                      type="checkbox"
                      checked={openLocation}
                      onChange={() => setOpenLocation(!openLocation)}
                    />
                    <label htmlFor="db1" className="drop-button">
                      <img src="Images/select-icon.png" alt="" />
                    </label>
                    <div className="drop-pop">
                      <h2>Select District</h2>
                      {city.map((district, index) => {
                        const isSelected = selectedLocations.find(
                          (location) => location.dist_id == district.dist_id
                        );
                        return (
                          <div className="place_d" key={index}>
                            <input
                              type="checkbox"
                              className="cursor-pointer"
                              value={district?.dist_id}
                              checked={isSelected ? true : false}
                              onChange={(e) => {
                                //e.preventDefault();
                                handleDistrictChange(district);
                              }}
                            />
                            <h6 style={{ marginBottom: "0" }}>
                              {district?.district}
                            </h6>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="selectplaces">
            {selectedLocations.map((location, index) => (
              <div className="placename" key={index}>
                <h5>{location?.district}</h5>
                <button
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDistrictChange(location);
                  }}
                >
                  <img src="Images/close.svg" alt="" />
                </button>
              </div>
            ))}
          </div>
        </form>
        <form className="row mb-3">
          <div className="col">
            <p className="m-0">Star</p>
          </div>
          <div className="col d-flex justify-content-end">
            <div className="hero-star">
              <div className="button-drop-star-container">
                <form className="button-group" style={{ margin: "0px" }}>
                  <div className="drop-star-button-wrap">
                    <input
                      id="db2"
                      type="checkbox"
                      checked={openStar}
                      onChange={() => setOpenStar(!openStar)}
                    />
                    <label htmlFor="db2" className="drop-star-button">
                      <img src="Images/select-icon.png" alt="" />
                    </label>
                    <div className="drop-star-pop">
                      <h2>Select Star</h2>
                      <div className="scrollable-list">
                        {stars.map((star, index) => {
                          const isSelected = selectedStars.find(
                            (item) => item.id == star.id
                          );
                          return (
                            <div className="place_d" key={index}>
                              <input
                                type="checkbox"
                                className="cursor-pointer"
                                value={star?.id}
                                checked={isSelected ? true : false}
                                onChange={(e) => {
                                  //e.preventDefault();
                                  handleStarChange(star);
                                }}
                              />
                              <h6 style={{ marginBottom: "0" }}>{star?.star}</h6>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="selectplaces">
            {selectedStars.map((star, index) => (
              <div className="placename" key={index}>
                <h5>{star?.star}</h5>
                <button
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    handleStarChange(star);
                  }}
                >
                  <img src="Images/close.svg" alt="" />
                </button>
              </div>
            ))}
          </div>
        </form>

        <Button
          id="search-value-button"
          className="searchButtons-search"
          type="submit"
          onClick={handleSearchValue}
        >
          Search
        </Button>

        {SearchValueResult &&
          SearchValueResult.length > 0 &&
          searchDataValue.length > 0 && (
            <SearchValueResult data={searchDataValue} />
          )}

        {!SearchValueResult ||
          (searchDataValue.length === 0 && search_valueNotFoundMessage && (
            <div className="user-not-found">
              {search_valueNotFoundMessage || "User not found"}
            </div>
          ))}
        <div className="desktop-pagination">
          {pagination?.totalPages > 1 && (
            <div className="pagination-container">
              <ReactPaginate
                previousLabel={
                  <i className="fa pagination-next fa-angle-left"></i>
                }
                nextLabel={
                  <i className="fa pagination-next fa-angle-right"></i>
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pagination?.totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePagination}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageClassName={"page-item"} // Define a CSS class for each page number element
                pageLinkClassName={"page-link"} // Define a CSS class for the page number link
              />
            </div>
          )}
        </div>
      </div>
      {/*  user not found message */}
    </MDBCol>
  );
}

export default Search_container;

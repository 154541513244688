import React, { useState } from "react";

import { MDBCard, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import Footer from "./Footer";
import { Button, Container } from "react-bootstrap";
import "../style/upgrade.css";
import Navbars from "./Navbars";
import ProfileSideBar from "./ProfileSideBar";
import InterationSection from "./Interations/InterationSection";

import { FaIndianRupeeSign } from 'react-icons/fa6'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RxCross2 } from "react-icons/rx";




function Upgrade() {
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );
  //packages//
  const packagesettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="registerbody">
      <Navbars
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        setTitleDiscription={setTitleDiscription}
      />
      {selectedTab === "Home" ? (
        <MDBContainer className=" mt-5 ">
          <div className="registerform">
            <div className="rgtr">
              <div className="bannr">
                <img
                  src="../Images/panchami_banner.jpg"
                  className="img-fluid "
                  alt="..."
                  style={{ width: 100 }}
                />
              </div>
            </div>
            <MDBContainer>
              <MDBRow className="uprade-items">

                <div className="home-packages">
                  <Slider {...packagesettings} className="homesuccess-slider-packeges">
                    <MDBCard className="cardonly-highlight-packages-page" style={{ width: "30%" }}>
                      <div className="mx-auto text-center " >
                        {" "}
                        <img
                          src="../Images/FREE MEMBER (1).png"
                          className="bronza-packages-home"
                        ></img>
                      </div>
                      <p className="bronze-package-home">Free Registration</p>
                      <div className="another-packages-login ">
                        <p>
                          {" "}
                          <span style={{ color: 'red', fontWeight: 'bold', marginRight: '6px' }}>  <RxCross2 /></span>0 Contact view Per day
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>5 Express Interest Per day
                        </p>
                        <p>
                          {" "}
                          <span style={{ color: 'red', fontWeight: 'bold', marginRight: '6px' }}>  <RxCross2 /></span>Chat with Prospects Directly
                        </p>
                        <p>
                          {" "}
                          <span style={{ color: 'red', fontWeight: 'bold', marginRight: '6px' }}>  <RxCross2 /></span>View Premium matches
                        </p>
                        {/*   <p className="full-cash">
                <FaIndianRupeeSign />
                <span className="cash">0</span>
              </p> 
                <h6 className="package-days">Free
                  validity</h6> */}
                        <div className="full-cash ">
                          <FaIndianRupeeSign />
                          <span className="cash">0</span>
                        </div>
                        <h6 className="package-days">Free validity</h6>
                        <Button href="#" className="upgrade-submit-home" type="submit">
                          Submit
                        </Button>
                      </div>
                    </MDBCard>
                    <MDBCard className="cardonly-highlight-packages-page" style={{ width: "30%" }}>
                      <div className="mx-auto text-center" >
                        {" "}
                        <img
                          src="../Images/upgrade3.png"
                          className="bronza-packages-home"
                        ></img>
                      </div>
                      <p className="bronze-package-home">Bronze Package</p>
                      <div className="another-packages-login">
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>150 Contact view Per day
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>150 Express Interest Per day
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>Chat with Prospects Directly
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>View Premium matches
                        </p>
                        <div className="full-cash">
                          <FaIndianRupeeSign />
                          <span className="cash">1000</span>
                        </div>
                        <h6 className="package-days">1 month</h6>
                        <Button href="#" className="upgrade-submit-home" type="submit">
                          Submit
                        </Button>
                      </div>
                    </MDBCard>
                    <MDBCard className="cardonly-highlight-packages-page" style={{ width: "30%" }}>
                      <div className="mx-auto text-center " >
                        {" "}
                        <img
                          src="../Images/upgrade2.png"
                          className="bronza-packages-home"
                        ></img>
                      </div>
                      <p className="bronze-package-home">Silver Package</p>
                      <div className="another-packages-login">
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>300 Contact view Per day
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>300 Express Interest Per day
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>Chat with Prospects Directly
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>View Premium matches

                        </p>
                        <div className="full-cash">
                          <FaIndianRupeeSign />
                          <span className="cash">2500</span>
                        </div>
                        <h6 className="package-days">3 Months</h6>
                        <Button href="#" className="upgrade-submit-home" type="submit">
                          Submit
                        </Button>
                      </div>
                    </MDBCard>

                    <MDBCard className="cardonly-highlight-packages-page" style={{ width: "30%" }}>
                      <div className="mx-auto text-center " >
                        {" "}
                        <img
                          src="../Images/upgrade1.png"
                          className="bronza-packages-home"
                        ></img>
                      </div>
                      <p className="bronze-package-home">Gold Package</p>
                      <div className="another-packages-login">
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>600 Contact view Per day
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>600 Express Interest Per day
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>Chat with Prospects Directly
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>View Premium matches
                        </p>
                        <div className="full-cash">
                          <FaIndianRupeeSign />
                          <span className="cash">4000</span>
                        </div>
                        <h6 className="package-days">6 months</h6>
                        <Button href="#" className="upgrade-submit-home" type="submit">
                          Submit
                        </Button>
                      </div>
                    </MDBCard>
                    <MDBCard className="cardonly-highlight-packages-page" style={{ width: "30%" }}>
                      <div className="mx-auto text-center " >
                        {" "}
                        <img
                          src="../Images/Diamond (1).png"
                          className="bronza-packages-home"
                        ></img>
                      </div>
                      <p className="bronze-package-home">Diamond Package</p>
                      <div className="another-packages-login">
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>1000 Contact view Per day
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>1000 Express Interest Per day
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>Chat with Prospects Directly
                        </p>
                        <p>
                          {" "}
                          <img src="../Images/ok.png"></img>View Premium matches
                        </p>
                        <div className="full-cash">
                          <FaIndianRupeeSign />
                          <span className="cash">7500</span>
                        </div>
                        <h6 className="package-days">12 Months</h6>
                        <Button href="#" className="upgrade-submit-home" type="submit">
                          Submit
                        </Button>
                      </div>
                    </MDBCard>
                  </Slider>
                </div >
              </MDBRow >
            </MDBContainer >
          </div >
        </MDBContainer >
      ) : (
        <MDBRow className="myhome-matches-all">
          <ProfileSideBar
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setTitleDiscription={setTitleDiscription}
          />
          <InterationSection
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            titleDiscription={titleDiscription}
            setTitleDiscription={setTitleDiscription}
          />
        </MDBRow>
      )
      }

      <Footer />
    </div >
  );
}

export default Upgrade;

import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Navbars from "../Navbars";
import "../../style/slider.css";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import ProfileSideBar from "../ProfileSideBar.js";
import Messagemenu from "../Messagemenu.js";
import Requests from "./Requests.js";
import History from "./History.js";
import InterationSection from "../Interations/InterationSection.js";
import Footer from "../Footer.js";
import Chat from "../Chat.js";
import Ignored from "./Ignored.js";

const Messages = () => {
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );
  const [tabActive, setTabActive] = useState("requests");
  const [chatData, setChatData] = useState({
    status: false,
    userId: "",
    name: "",
    image: "",
    from: "",
  });
  useEffect(() => {}, []);

  const changeTabs = (value) => {
    if (tabActive === value) {
      return;
    }
    setTabActive(value);
  };

  return (
    <div className="back">
      <Navbars
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        setTitleDiscription={setTitleDiscription}
      />
      <Container>
        <MDBRow className=" ml-3 myhome-matches-all">
          <ProfileSideBar
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setTitleDiscription={setTitleDiscription}
          />
          {selectedTab === "Home" ? (
            <MDBCol size="6" sm="9" className="messages-mobile">
              <Messagemenu tabActive={tabActive} changeTabs={changeTabs} />
              {tabActive === "requests" && (
                <Requests setChatData={setChatData} />
              )}
              {tabActive === "history" && <History setChatData={setChatData} />}
              {tabActive === "ignored" && <Ignored setChatData={setChatData} />}
            </MDBCol>
          ) : (
            <InterationSection
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              titleDiscription={titleDiscription}
              setTitleDiscription={setTitleDiscription}
            />
          )}
        </MDBRow>
      </Container>
      {chatData?.status && (
        <Chat
          chatData={{
            userId: chatData?.userId,
            name: chatData?.name,
            image: chatData?.image,
          }}
          onClose={() =>
            setChatData({
              status: false,
              userId: "",
              name: "",
              image: "",
            })
          }
          from={chatData?.from}
        />
      )}
      <Footer />
    </div>
  );
};

export default Messages;

import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from "react";

const SimilarProfiles = ({ profiles }) => {
  return (
    <div className=" editprofiletyping similer-profile-profileedit">
      <p className="similer-properties">Similar Profiles</p>
      <hr></hr>

      {/* <div className="scrollable-container "> */}
      <div>
        {profiles.length > 0 ? (
          profiles.map((profile, index) => (
            <MDBRow className="similer-profile-profileedit" key={index}>
              <MDBCol size="6" sm="5">
                <div className={profile?.profile_image ? 'similer-profiles-img' : ''}>
                  <img
                    src={profile?.profile_image || "/default-photo-url.png"}
                    className={`similer-images img-fluid${!profile?.profile_image ? " default-profile-image" : ""}`}
                    alt="Profile Image"
                  />
                </div>
              </MDBCol>
              <MDBCol size="6" sm="7" className="editprofile-only">
                <p className="editprofile-name" style={{marginBottom:'0.2rem'}}>{profile?.name}</p>
                <p className="editprofile-id">
                  {profile?.profile_id}|Profile Created by
                  {profile?.created_for || " Not Defined"} ,<br></br>
                  {profile?.age}Yrs,{" "}
                  {profile?.height_feet || profile?.height_cm
                    ? `${profile?.height_feet || "height"} / ${profile?.height_cm || "height"
                    } cm`
                    : "height not defined"}
                </p>
                <a
                  className="editprofile-seemore"
                  href={`/profile-details/${profile?.profile_id}`}
                >
                  See More
                </a>
              </MDBCol>
            </MDBRow>
          ))
        ) : (
          <p className="loading py-5">No similar profiles</p>
        )}
      </div>
    </div>
  );
};

export default SimilarProfiles;

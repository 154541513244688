import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from "react";

const ProfileDescriptions = ({ userData }) => {
  return (
    <div>
      <h4 style={{ textAlign: "center", margin: "20px 0px" }}>
        Profile Details
      </h4>
      <div className="profile-editprofile">
        <p className="profile-description-profiledetails">
          Profile Description
        </p>
        <p className="profile-des">
          {userData?.profile_description || "not defined"}
        </p>
        <hr />
        <p className="profile-description">Basic Details</p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Name
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.name}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Age
              </div>
              <div style={{ flex: 1 }}>
                : {userData?.age ? `${userData?.age} Years` : "not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Height
              </div>
              <div style={{ flex: 1 }}>
                :{" "}
                {userData?.height_feet
                  ? `${userData?.height_feet} feet`
                  : "not defined"}{" "}
                ,{" "}
                {userData?.height_cm
                  ? `${userData?.height_cm} cm`
                  : "height not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Gender
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.gender || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Marital Status
              </div>
              <div style={{ flex: 1 }}>
                :{" "}
                {userData?.matrial_status
                  ? userData?.matrial_status
                  : "not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Physical Status
              </div>
              <div style={{ flex: 1 }}>
                :{" "}
                {userData?.physical_status
                  ? userData?.physical_status
                  : "not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />
        <p className="profile-description">Religious Details </p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            {" "}
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Religion
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.religion || " not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Caste
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.caste || " not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />
        <p className="profile-description">Professional Details</p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
               Highest Education
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.qualification_main || " not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Qualification
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.qualification_sub || " not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Employed in
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.employed_in || " not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Occupation details
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.occupation || " not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Annual income
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.annual_income || " not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />
        <p className="profile-description">Location Details</p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                State
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.state || " not defined"}
              </div>
            </div>
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                District
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.district || " not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                City
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.city || " not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />
        <p className="profile-description">Family Details</p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Family value
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.family_value || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Father's Occupation
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.father_occupation || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                No. of Brothers
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.no_of_brothers || "not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Family Type
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.family_type || " not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Family Status
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.family_status || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Mother's Occupation
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.mother_occupation || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                No. of Sisters
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.no_of_sisters || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Family Location
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.family_location || " not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />

        <p className="profile-description">Habits</p>
        <MDBRow>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Eating Habits
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.eating_habit || " not defined"}
              </div>
            </div>

            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Smoking Habit
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.smoking_habit || " not defined"}
              </div>
            </div>
          </MDBCol>
          <MDBCol className="col-12 col-md-6">
            <div className="annual-income" style={{ display: "flex" }}>
              <div style={{ flex: 1 }} className="full-bolder">
                Drinking Habit
              </div>
              <div style={{ flex: 1 }}>
                :{""} {userData?.drinking_habit || " not defined"}
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <hr />
        <p className="profile-description">Hobbies</p>
        <MDBRow>
          <div className="hobbies_div" style={{ display: "flex" }}>
            {userData.hobbies && userData.hobbies.length > 0 ? (
              <ul className="ks-cboxtags view_dh">
                {userData.hobbies.map((hobby) => (
                  <li key={hobby.id}>
                    <label>{hobby.hobbies}</label>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Not Defined</p>
            )}
          </div>
        </MDBRow>

      </div>
    </div>
  );
};

export default ProfileDescriptions;

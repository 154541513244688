import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";

const ReligiousDetails = ({ userData, religionCaste }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [religiousData, setReligiousData] = useState({
    religion_id: "",
    religion: "",
    caste_id: "",
    caste: "",
  });
  const [casteList, setCasteList] = useState([]);
  const [isEditingReligious, setIsEditingReligious] = useState(false);

  useEffect(() => {
    const selectedData = religionCaste.find(
      (caste) => caste.religion === userData?.religion
    );
    setReligiousData({
      religion_id: selectedData?.id,
      religion: userData?.religion,
      caste: userData?.caste,
    });
    if (selectedData) {
      setCasteList([...selectedData?.caste]);
    }
  }, [userData]);

  const handleEditClickReligious = () => {
    setIsEditingReligious(true);
  };

  const handleReligionChange = (event) => {
    const selectedReligion = event.target.value;
    if (selectedReligion) {
      const selectedData = religionCaste.find(
        (caste) => caste.religion === selectedReligion
      );
      setReligiousData({
        religion_id: selectedData?.id,
        religion: selectedReligion,
        caste: "",
      });
      setCasteList([...selectedData?.caste]);
    } else {
      setReligiousData({
        religion_id: "",
        religion: "",
        caste: "",
      });
      setCasteList([]);
    }
  };

  const handleCasteChange = (event) => {
    const selectedCaste = event.target.value;
    if (selectedCaste) {
      const findCaste = casteList.find(
        (caste) => caste.caste === selectedCaste
      );
      setReligiousData({
        ...religiousData,
        caste_id: findCaste?.id,
        caste: selectedCaste,
      });
    } else {
      setReligiousData({
        ...religiousData,
        caste_id: "",
        caste: "",
      });
    }
  };

  const handleSaveClickReligious = async () => {
    const payload = {
      religion: religiousData?.religion_id,
      caste: religiousData?.caste_id,
    };
    await api.updateReligion(payload, token).then((data) => {
      if (data.status) {
        setIsEditingReligious(false);
      } else if (data.status === 401) {
        navigate("/login");
        localStorage.clear();
      }
    });
  };

  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Religious Details</p>
        <div className="editing-religious">
          {isEditingReligious ? (
            <button onClick={handleSaveClickReligious}>Save</button>
          ) : (
            <button onClick={handleEditClickReligious}>
              <MDBIcon far icon="edit" /> Edit
            </button>
          )}
        </div>
      </div>

      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingReligious ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Religion
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="religion"
                name="religion"
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                value={religiousData?.religion}
                onChange={handleReligionChange}
              >
                <option value="">Select </option>
                {religionCaste.map((religion) => (
                  <option key={religion.id} value={religion.religion}>
                    {religion.religion}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Religion
            </div>
            <div style={{ flex: 1 }}>
              :{""} {religiousData?.religion}
            </div>
          </div>
        )}
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingReligious ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Caste
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="caste"
                name="caste"
                className="SelectBoxs-searchpage-profileedit"
                value={religiousData?.caste}
                onChange={handleCasteChange}
              >
                <option value="">Select</option>
                {casteList.map((caste) => (
                  <option key={caste.id} value={caste.caste}>
                    {caste.caste}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Caste
            </div>
            <div style={{ flex: 1 }}>
              :{""} {religiousData?.caste}
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default ReligiousDetails;

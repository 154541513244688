import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import MatchSlider from "./MatchSlider";
import NewmatchSlider from "./NewmatchSlider";
import MatchSliderComponent from "./MatchSliderComponent";

const NewMatches = ({ newMatches }) => {
  const [screenWidth, setScreenWidth] = useState(null);
  useEffect(() => {
    // Function to update screen width
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener to update screen width on resize
    window.addEventListener("resize", updateScreenWidth);

    // Initial call to set the initial screen width
    updateScreenWidth();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  return (
    <div className="new-matches">
      <p className="matches-profile">
        <span className="new-matches-only">
          {newMatches?.length > 150
            ? "150+ "
            : newMatches?.length > 100
            ? "100+ "
            : newMatches?.length > 50
            ? "50+ "
            : newMatches?.length > 10
            ? "10+ "
            : newMatches?.length > 5 && "5+ "}
          New Matches
        </span>{" "}
        - Members who matches your preference
      </p>
      <MDBRow className="daily-yourpreference">
        {newMatches.length > 4 ? (
          <MatchSliderComponent newMatches={newMatches} />
        ) : screenWidth > 768 ? (
          newMatches.length > 3 && screenWidth < 1024 ? (
            <MatchSliderComponent newMatches={newMatches} />
          ) : (
            <div className="d-flex gap-3">
              {newMatches.map((match) => (
                <NewmatchSlider
                  key={match?.profile_id}
                  id={match?.profile_id}
                  name={match?.name}
                  age={match?.age}
                  image={match?.profile_image}
                />
              ))}
            </div>
          )
        ) : (
          <MatchSliderComponent newMatches={newMatches} />
        )}
      </MDBRow>
      {newMatches.length > 4 ? (
        <p className="viewall" style={{ textAlign: "center" }}>
          <a href="matches">View all</a>
        </p>
      ) : screenWidth > 768 ? (
        newMatches.length > 3 && screenWidth < 1024 ? (
          <p className="viewall" style={{ textAlign: "center" }}>
            <a href="matches">View all</a>
          </p>
        ) : (
          <></>
        )
      ) : (
        newMatches.length > 1 && (
          <p className="viewall" style={{ textAlign: "center" }}>
            <a href="matches">View all</a>
          </p>
        )
      )}
    </div>
  );
};

export default NewMatches;

import { MDBCol } from 'mdb-react-ui-kit';
import React from 'react'

const NewmatchSlider= ({ id, image, name, age }) => {
    return (
      <div className="newdaily-slider text-center">
        <a href={`profile-details/${id}`}>
        {/* <div className={image ? 'dailyimages-photo' : ''}>
    <img
        src={image || "default-photo-url.png"}
        className={image ? '' : 'dailyImgage'}
        height={160}
        alt={name}
    />
</div> */}
       <div className= {image ? 'dailyimages-photo' : ''}  >
          <img
            src={image || "default-photo-url.png"}
            className={image ? '' : 'dailyImgage'}   
            height={160}
            alt={name}
          />
          </div> 
  
          <p className="sebastian-full">{name}</p>
          <p className="sebastian-years">
            {age} years | {id}
          </p>
        </a>
      </div>
    );
  };

export default NewmatchSlider
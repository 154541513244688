import { axios, constants } from "../Config";

class SettingApiFetch {
    changePassword(payload, token) {
        return axios
            .post(`${constants.baseURL}change-password`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return { data: error.response.data };
                }
                throw new error("An unexpected error occurred.")
            });

    }
    DeleteAccount(payload, token) {
        return axios
            .post(`${constants.baseURL}delete-account`,payload,  {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return { data: error.response.data };
                }
                throw new error("An unexpected error occurred.")
            });
    }

    getDeleteReason(token) {
        return axios
            .get(`${constants.baseURL}get-delete-reason`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (error.response) {
                    return { data: error.response.data };
                }
                throw new error("An unexpected error occurred.")
            });
    }


}
export default new SettingApiFetch();
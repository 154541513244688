import React, { useState } from "react";
import "../../style/matches.css";
import Navbars from "../Navbars";
import Footer from "../Footer";
import { Container } from "react-bootstrap";
import Search from "./Search";
import Nva from "../Nva";

const SearchProfiles = () => {
  const token = localStorage.getItem("authToken");
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );

  return (
 
    <div className="matches-background">
   
      {token ? (
        <Navbars
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setTitleDiscription={setTitleDiscription}
        />
      ) : (
        <Nva />
      )}
     <div>
     <Container>
        <Search
          selectedTab={selectedTab}
          titleDiscription={titleDiscription}
          setSelectedTab={setSelectedTab}
          setTitleDiscription={setTitleDiscription}
        />
      </Container>
     </div>
      <Footer />

    </div>

  );
};

export default SearchProfiles;

import React, { useEffect, useState } from 'react'
import Navbars from '../Navbars'
import Nva from '../Nva'
import Story from './Story';
import { MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import Post from './Post';
import Footer from '../Footer';

function SuccessStory() {
  const [selectTab, setSelectTab] = useState('');
  const token = localStorage.getItem('authToken');
  return (
    <div className="registerbody">
      {token ? <Navbars /> : <Nva />}
      <div className="registerbackground">
        <MDBContainer className="mt-5 post-story-success-paragraph">
          <div className="registerform">
            <div className="rgtr">
              <div className="bannr">
                <img src="../Images/panchami_banner.jpg" className="img-fluid " alt="..." style={{ width: 100 }} />
              </div>
            </div>
            
            {selectTab === 'post' ? <Post setSelectTab={setSelectTab} /> : <Story setSelectTab={setSelectTab} />}
          </div>
        </MDBContainer>
      </div>
      <Footer/>
    </div>
  )
}

export default SuccessStory
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../Api/ProfileApi";
import Navbars from "../Navbars";
import { Container } from "react-bootstrap";
import { MDBRow } from "mdb-react-ui-kit";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../style/profileedit.css";
import RightSide from "./RightSide";
import Footer from "../Footer";
import ProfileSide from "./ProfileSide";
import InterationSection from "../Interations/InterationSection";
import ProfileSideBar from "../ProfileSideBar";

const EditProfile = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [userImages, setUserImages] = useState([]);
  const [similarProfiles, setSimilarProfiles] = useState([]);
  const [listHeights, setListHeights] = useState([]);
  const [listStars, setListStars] = useState([]);
  const [religionCaste, setReligionCaste] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [hobbies, setHobbies] = useState([])
  const [employedInList, setEmployedInList] = useState([]);
  const [occupationList, setOccupationList] = useState([]);
  const [annualIncomeList, setAnnualIncomeList] = useState([]);
  const [stateAndDistricts, setStateAndDistricts] = useState([]);
  const [sports, setSports] = useState([]);
  const [arts, setArts] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Home");
  const [titleDiscription, setTitleDiscription] = useState(
    "Members you have interest"
  );

  useEffect(() => {
    return () => {
      fetchUserProfileDerails();
    };
  }, []);

  const fetchUserProfileDerails = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        document.cookie =
          "connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        // Clear the local storage item (if you use it for storing the token)
        localStorage.clear();
        navigate("/login");
        return;
      }

      await api.getEditProfile(token).then(({ data }) => {
        if (data.status) {
          setUserData(data?.data?.user_profile);
          setUserImages(data?.data?.images);
          let similar_profiles =
            data?.data?.similar_profiles.length > 4
              ? data?.data?.similar_profiles.slice(3)
              : data?.data?.similar_profiles;
          setSimilarProfiles(similar_profiles);
          setListHeights(data?.data?.heights);
          setListStars(data?.data?.star);
          setReligionCaste(data?.data?.religionCaste);
          setEducationList(data?.data?.education);
          setEmployedInList(data?.data?.employed_in);
          setOccupationList(data?.data?.occupation);
          setAnnualIncomeList(data?.data?.annual_income);
          setStateAndDistricts(data?.data?.stateDistrict);
          setSports(data?.data?.sports);
          setArts(data?.data?.arts);
          setHobbies(data?.data?.hobbies)
        } else {
          if (data.statusCode === 401) {
            localStorage.clear();
            navigate("/login");
          }
        }
      });
    } catch (error) {
      console.error("Error fetching edit user profile details:", error);
    }
  };
  return (
    <div className="matches-background">
      <Navbars
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        setTitleDiscription={setTitleDiscription}
      />
      <Container>
        {selectedTab === "Home" ? (
          <MDBRow className="profileedit-matches-all">
            <ProfileSide
              userImages={userImages}
              similarProfiles={similarProfiles}
            />
            <RightSide
              userData={userData}
              setUserData={setUserData}
              listHeights={listHeights}
              listStars={listStars}
              religionCaste={religionCaste}
              educationList={educationList}
              employedInList={employedInList}
              occupationList={occupationList}
              annualIncomeList={annualIncomeList}
              stateAndDistricts={stateAndDistricts}
              sports={sports}
              arts={arts}
              hobbies={hobbies}
            />
          </MDBRow>
        ) : (
          <MDBRow className="myhome-matches-all">
            <ProfileSideBar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setTitleDiscription={setTitleDiscription}
            />
            <InterationSection
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              titleDiscription={titleDiscription}
              setTitleDiscription={setTitleDiscription}
            />
          </MDBRow>
        )}
      </Container>
      <Footer />
    </div>
  );
};

export default EditProfile;

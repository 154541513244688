import React, { useEffect, useState } from "react";

const Notification = ({ notification }) => {
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    if (notification) {
      const date = new Date(notification.notification_date);
      const now = new Date();
      const timeDifference = Math.floor((now - date) / 1000); // difference in seconds
  
      let formatted = '';
  
      if (timeDifference < 60) {
        formatted = "Just now";
      } else if (timeDifference < 3600) {
        const minutes = Math.floor(timeDifference / 60);
        formatted = `${minutes} min ago`;
      } else if (timeDifference < 86400) {
        const hours = Math.floor(timeDifference / 3600);
        formatted = `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else {
        const day = date.getDate();
        const month = date.getMonth() + 1; // Month is zero-indexed, so add 1
        const year = date.getFullYear();
  
        formatted = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
      }
  
      setFormattedDate(formatted);
    }
  }, [notification]);
  

  return (
    <div className="notification_box">
      <div className="d-flex not-flex">
        <div>
          <div className="image_not">
            <a href={`profile-details/${notification?.user_code}`}>
              <img
                className="notimage"
                src={
                  notification?.profile_image
                    ? notification?.profile_image
                    : "../default-photo-url.png"
                }
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="content_noti">
          <div>
            {notification?.notification_type === "interest_received" && (
              <h5 style={{ color: "#E0763A" }} className="message">
                <img
                  style={{ width: "20px" }}
                  src="../Images/recieve_new.svg"
                  alt=""
                />
                <span>Received interest</span>
                <span className="notification-date"> | {formattedDate}</span>
              </h5>
            )}
            {notification?.notification_type === "interest_accept" && (
              <h5 style={{ color: "#248C4E" }} className="message">
                <img
                  style={{ width: "20px" }}
                  src="../Images/accepted.svg"
                  alt=""
                />
                <span>Accepted Interest</span>
                <span className="notification-date"> | {formattedDate}</span>
              </h5>
            )}
            {notification?.notification_type === "interest_reject" && (
              <h5 style={{ color: "#DD1212" }} className="message">
                <img
                  style={{ width: "20px" }}
                  src="../Images/reject.svg"
                  alt=""
                />
                <span>Reject Interest</span>
                <span className="notification-date"> | {formattedDate}</span>
              </h5>
            )}
            {notification?.notification_type === "shortlist" && (
              <h5 style={{ color: "#E0763A" }} className="message">
                <img
                  style={{ width: "20px" }}
                  src="../Images/intrest_new.svg"
                  alt=""
                />
                <span>Shortlisted</span>
                <span className="notification-date"> | {formattedDate}</span>
              </h5>
            )}
            <a href={`profile-details/${notification?.user_code}`}>
              <h3>{notification?.name} </h3>
            </a>
            <a href={`profile-details/${notification?.user_code}`}>
              <h6>{notification?.user_code}</h6>
            </a>
            <p>{notification?.notification}</p>
            <p> {notification?.date}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;

import "../../style/login.css";
import "firebase/messaging";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import Nva from "../Nva";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Footer from "../Footer";
import api from "../../Api/AuthApi";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import OtpValidation from "./OtpValidation";
import ResetPassword from "./ResetPassword";
import { firebaseApp, generateToken } from "../../firebase/firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  getFcmToken,
  setFCMToken,
  setProfileDetails,
} from "../../redux/features/profile";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fcmToken = useSelector(getFcmToken);
  const firebaseDB = getFirestore(firebaseApp);
  const [loginData, setLoginData] = useState({
    userName: "",
    userNameError: "",
    password: "",
    passwordError: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState({
    status: false,
    message: "",
  });
  const [modalType, setModalType] = useState("");
  const [otpExpiration, setOtpExpiration] = useState(0);
  const [forgotPassword, setForgotPassword] = useState({
    user: "",
    authToken: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      navigate("/login");
      return;
    } else {
      navigate("/my-home");
    }
  }, [navigate]);

  useEffect(() => {
    handleFCMToken();
  }, []);

  const handleFCMToken = async () => {
    await generateToken().then((data) => {
      dispatch(setFCMToken(data));
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleUser = (e) => {
    let { name, value } = e.target;
    if (name === "username") {
      if (value) {
        setLoginData((prevValue) => ({
          ...prevValue,
          userNameError: "",
        }));
      } else {
        setLoginData((prevValue) => ({
          ...prevValue,
          userNameError: "Please enter Mobile number/Email id",
        }));
      }

      setLoginData((prevValue) => ({
        ...prevValue,
        userName: value,
      }));
    } else if (name === "password") {
      if (value) {
        setLoginData((prevValue) => ({
          ...prevValue,
          passwordError: "",
        }));
      } else {
        setLoginData((prevValue) => ({
          ...prevValue,
          passwordError: "Please enter your password",
        }));
      }

      setLoginData((prevValue) => ({
        ...prevValue,
        password: value,
      }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    try {
      if (!loginData?.userName) {
        setLoginData((prevValue) => ({
          ...prevValue,
          userNameError: "Please enter Mobile number/Email id",
        }));
      }

      if (!loginData?.password) {
        setLoginData((prevValue) => ({
          ...prevValue,
          passwordError: "Please enter your password",
        }));
      }

      if (loginData?.userName && loginData?.password) {
        const payload = {
          username: loginData?.userName,
          password: loginData?.password,
          fcmtoken: fcmToken,
        };
        api.login(payload).then( async ({ data }) => {
          if (data.status) {
            localStorage.setItem("authToken", data?.data?.token);
            dispatch(setProfileDetails({ profile_id: data?.data?.userCode }));
            await updateOnlineStatus(data?.data?.userCode)
            navigate("/my-home", { state: { token: data?.data?.token } });
          } else {
            setLoginError({
              status: true,
              message: data?.message,
            });
          }
        });
      }
    } catch (err) {
      console.log("err", err);
      setLoginError({
        status: true,
        message: "Sorry, something went wrong",
      });
    }
  };

  const updateOnlineStatus = async (userId) => {
    const usersDocRef = doc(firebaseDB, "users", userId);
    const userDocSnap = await getDoc(usersDocRef);
    const currentTimeInUTC = new Date().toUTCString();
    if (!userDocSnap.exists()) {
      const newUuid = await uuid();
      await setDoc(doc(firebaseDB, "users", userId), {
        id: newUuid,
        userId: userId,
        status: "ONLINE",
        time: currentTimeInUTC,
      });
    } else {
      updateDoc(doc(firebaseDB, "users", userId), {
        status: "ONLINE",
        time: currentTimeInUTC,
      });
    }
  };

  return (
    <div className="registerbody">
      <Nva />
      <section className="loginbody">
        <MDBContainer className="my-5 loginbackground">
          <MDBCard className="loginregister">
            <MDBRow className="g-0 loginonly">
              <MDBCol md="6">
                <MDBCardImage
                  src="Images/logcnetr2.png"
                  alt="login form"
                  className="rounded-start w-100"
                />
              </MDBCol>
              <MDBCol md="6" className="loginform">
                <MDBCardBody className="d-flex flex-column">
                  <Form className="formfull">
                    <MDBContainer className="d-flex flex-column">
                      <h3
                        className="fw-normal  pb-3"
                        style={{
                          letterSpacing: "1px",
                          color: "#65044A",
                          fontSize: "22px",
                        }}
                      >
                        Member Login
                      </h3>
                      <MDBInput
                        wrapperClass="mb-2"
                        className="mailid"
                        label="Profile ID/Mail ID/Mobile Number"
                        id="username"
                        type="text"
                        size="lg"
                        name="username"
                        value={loginData?.userName}
                        onChange={handleUser}
                      />
                      {loginData?.userNameError && (
                        <p
                          className="text-danger"
                          style={{ fontSize: "12px", lineHeight: "1" }}
                        >
                          {loginData?.userNameError}
                        </p>
                      )}

                      <div style={{ position: "relative" }}>
                        <MDBInput
                          wrapperClass="mb-2"
                          label="Password"
                          id="password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          size="lg"
                          value={loginData?.password}
                          onChange={handleUser}
                        />
                        <span
                          className="password-toggle-icon-login"
                          onClick={handleTogglePassword}
                          style={{
                            position: "absolute",
                            top: "48%",
                            color: "#65044A",
                            right: 17,
                            transform: "translateY(-70%)",
                            cursor: "pointer",
                          }}
                        >
                          {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                      </div>
                      {loginData?.passwordError && (
                        <p
                          className="text-danger"
                          style={{ fontSize: "12px", lineHeight: "1" }}
                        >
                          {loginData?.passwordError}
                        </p>
                      )}

                      <MDBBtn
                        type="submit"
                        className="mb-2 mt-2 px-5 loginsection"
                        size="lg"
                        onClick={onSubmit}
                      >
                        Login
                      </MDBBtn>
                      {loginError.status && (
                        <p className="text-danger">{loginError.message}</p>
                      )}
                    </MDBContainer>
                  </Form>
                  <p className="small   " href="registration">
                    Don't have an account?
                    <a href="registration">
                      <span className="signup">Register Free</span>
                    </a>
                  </p>
                  <MDBBtn
                    onClick={() => setModalType("forgot")}
                    className="small smollforgot "
                  >
                    <span className="signup">Forgot Password?</span>
                  </MDBBtn>

                  {/* modals */}
                  {modalType === "forgot" && (
                    <ForgotPassword
                      modalType={modalType}
                      setModalType={setModalType}
                      setOtpExpiration={setOtpExpiration}
                      setForgotPassword={setForgotPassword}
                    />
                  )}
                  {modalType === "otp" && (
                    <OtpValidation
                      modalType={modalType}
                      setModalType={setModalType}
                      otpExpiration={otpExpiration}
                      setOtpExpiration={setOtpExpiration}
                      forgotPassword={forgotPassword}
                      setForgotPassword={setForgotPassword}
                    />
                  )}
                  {modalType === "password" && (
                    <ResetPassword
                      modalType={modalType}
                      setModalType={setModalType}
                      forgotPassword={forgotPassword}
                    />
                  )}
                  {modalType === "success" && (
                    <MDBModal
                      show={modalType === "success" ? true : false}
                      onHide={() => {
                        setModalType("");
                      }}
                      tabIndex="-1"
                      className="model-full"
                    >
                      <MDBModalDialog>
                        <MDBModalContent>
                          <MDBModalHeader className="forgotpassword-header">
                            <div className="forgot-header">
                              <MDBModalTitle>Successfully</MDBModalTitle>
                            </div>
                            <MDBBtn
                              className="btn-close btn-close-white"
                              color="none"
                              onClick={() => setModalType("")}
                            ></MDBBtn>
                          </MDBModalHeader>
                          <div className="my-3">
                            <p
                              className="password-reset"
                              style={{ color: "black", textAlign: "center" }}
                            >
                              Your password has been reset. Please Login
                            </p>
                          </div>
                        </MDBModalContent>
                      </MDBModalDialog>
                    </MDBModal>
                  )}
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </MDBContainer>
      </section>
      <Footer />
    </div>
  );
};

export default Login;

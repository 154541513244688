
import Navbars from './Navbars'
import '../style/profileedit.css'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { Button, Container, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import '../style/datePicker.css';
import React, { useEffect, useState } from 'react';

import Footer from './Footer'
import { MDBIcon } from 'mdb-react-ui-kit';

//backend-api//
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import instance from '../axios'
import { useLoaderData, useNavigate } from 'react-router-dom';
import Footerinner from './Footerinner';


function Profileedit() {


  const navigate = useNavigate()
  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        navigate('/login');
        return;
      }

      const tokenData = parseToken(token);

      if (tokenData && tokenData.exp) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (tokenData.exp < currentTime) {
          // Token has expired, clear it and navigate to login
          clearToken();
          navigate('/login');
        }
      }

    };

    checkTokenExpiration();
  }, [navigate]);


  function parseToken(token) {
    try {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      return tokenData;
    } catch (error) {
      return null;
    }
  }


  function clearToken() {
    localStorage.removeItem('authToken');

  }
  // DATE PICKER TESTING
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  const minDate = new Date(today.getFullYear() - 90, today.getMonth(), today.getDate());

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [validationError, setValidationError] = useState(false);
  /* 
   const handleDateChange = (date) => {  
      const formattedDate = date.toISOString().split('T')[0];  
      console.log("formattedDate", formattedDate);
      const age = calculateAge(formattedDate);    
      setSelectedDate(date);
      setBasicDetails({
        ...basicDetails,
        dateOfBirth: formattedDate,
        age: age.toString(), 
      });
    }; 
     */
  const handleDateChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2-digit month
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2-digit day
    const formattedDate = `${year}-${month}-${day}`;

    const age = calculateAge(formattedDate);

    setSelectedDate(date);
    setBasicDetails({
      ...basicDetails,
      dateOfBirth: formattedDate,
      age: age.toString(),
    });
  };


  //backend

  //photo view
  const [userPhoto, setuserPhoto] = useState(null);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken'); // Retrieve the token from storage
        if (!token) {
          // Handle missing token
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/viewphoto', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data', response);
        setuserPhoto(response.data.data);
        console.log('response data data : ', response.data.data);

      } catch (error) {
        console.error('Error fetching user details:', error);

      }
    };

    fetchUserDetails();
  }, []);

  // view logged user
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/viewedUserProfile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data', response);
        const fetchedUserData = response.data.userData;

        // Set the fetched user data to the state
        setUserData(fetchedUserData);

        // Log the user data
        console.log('Fetched User Data:', fetchedUserData);
        console.log('User Name:', fetchedUserData.c_name);
        console.log(userData.c_name);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  //height fetch
  const [height, setHeight] = useState([]);
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/view_height')
      .then(response => {
        setHeight(response.data);
        console.log('Height : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching Occupation:', error);
      });
  }, []);

  //star fetch
  const [star, setStar] = useState([]);
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/view_star')
      .then(response => {
        setStar(response.data);
        console.log('star : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching Occupation:', error);
      });
  }, []);

  //cities fetch
  const [cities, setCities] = useState([]);
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/show_cities')
      .then(response => {
        setCities(response.data);
        console.log('cities : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching Occupation:', error);
      });
  }, []);

  //education fetch
  const [education, setEduccation] = useState([])
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/show_qualification')
      .then(response => {

        setEduccation(response.data);
        console.log('Education : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching States:', error);
      });
  }, []);
  // employeed in fetch
  const [employeed_in, setEmployeed_in] = useState([])
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/view_employeed_in')
      .then(response => {

        setEmployeed_in(response.data);
        console.log('employeed_in : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching States:', error);
      });
  }, []);

  //fetch occupation
  const [occupation, setOccupation] = useState([])
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/show_occupatoin')
      .then(response => {
        setOccupation(response.data);
        console.log('Occupation : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching Occupation:', error);
      });
  }, []);

  //fetch annual income
  const [annual_income, setAnnual_income] = useState([])
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/view_annual_income')
      .then(response => {
        setAnnual_income(response.data);
        console.log('annual income : ', response.data);

      })
      .catch(error => {
        console.error('Error fetching annual income:', error);
      });
  }, []);

  // sports fetch
  const [sports, setSports] = useState([])
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/show_sports')
      .then(response => {
        setSports(response.data);
      })
      .catch(error => {
        console.error('Error fetching annual income:', error);
      });
  }, []);

  // arts fetch
  const [arts, setArts] = useState([])
  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/show_arts')
      .then(response => {
        setArts(response.data);
      })
      .catch(error => {
        console.error('Error fetching annual income:', error);
      });
  }, []);

  //fetch state district city
  const [formData, setFormData] = useState({
    state: '',
    district: '',
    city: '',
    religion: '',
    caste: '',
  });

  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/show_states')
      .then(response => {

        setStateList(response.data);
        console.log('States :', response.data);

      })
      .catch(error => {
        console.error('Error fetching States:', error);
      });
  }, []);


  useEffect(() => {
    if (formData.state) {
      console.log('FormData.state : ', formData.state);
      fetchDistricts(formData.state);
    }

  }, [formData.state]);

  const fetchDistricts = async (selectedState) => {
    try {
      console.log('FormData.state in fetchDistricts:', selectedState);
      instance.get(`api/pulayakalyanam/admin/show_district?state=${selectedState}`)
        .then(response => {
          console.log("hai district");
          setDistrictList(response.data);
          console.log('States', response.data);

        })
    } catch (error) {
      console.error('Error fetching districts:', error);
    }
  };

  useEffect(() => {
    if (formData.district) {
      console.log('FormData.district : ', formData.district);
      fetchCity(formData.district);
    }
  }, [formData.district]);

  const fetchCity = async (selectedCity) => {
    try {
      console.log('FormData.District in fetchCity:', selectedCity);
      instance.get(`api/pulayakalyanam/admin/show_city?district=${selectedCity}`)
        .then(response => {
          console.log("haidistrict");
          setCityList(response.data);
          console.log('City', response.data);

        })
    } catch (error) {
      console.error('Error fetching districts:', error);
    }
  };


  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    console.log("formdata state", selectedState);
    setFormData({ ...formData, state: selectedState });
  };

  const handleDistrictChange = (event) => {
    const selectedDistrict = event.target.value;
    setFormData({ ...formData, district: selectedDistrict });
  };

  const handleCityChange = (event) => {
    const selectedCity = event.target.value;
    setFormData({ ...formData, city: selectedCity });
  };



  //view and edit

  //description//
  const [profileDescription, setProfileDescription] = useState('');
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const handleEditClickDescription = () => {
    setIsEditingDescription(true);
  };

  const handleSaveClickDescription = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Authentication token missing');
        return;
      }

      const response = await instance.put(
        '/api/pulayakalyanam/profileDescription_update',
        {
          profile_description: profileDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditingDescription(false); // Disable edit mode
        console.log('Profile description updated successfully');

        // Update the profileDescription state with the new value
        setProfileDescription(profileDescription);

        // Update the userData state with the new description
        const updatedUserData = { ...userData, c_prof_description: profileDescription };
        console.log("description", updatedUserData);
        setUserData(updatedUserData);

      } else {
        console.error('Failed to update profile description');
      }
    } catch (error) {
      console.error('Error updating profile description:', error);
    }
  };

  // Basic Details
  const [isEditingBasicdetails, setIsEditingBasicdetails] = useState(false);

  const [basicDetails, setBasicDetails] = useState({});
  useEffect(() => {
    if (userData) {
      setBasicDetails({
        name: userData.c_name || '',
        dateOfBirth: userData.d_dob || '',
        age: userData.n_age || '',
        height_id: userData.c_height || '',
        ft_height: userData.n_feet || '',
        height: userData.n_cm || '',
        gender: userData.c_gender || '',
        maritalStatus: userData.c_marital_status || '',
        physicalStatus: userData.c_physical_status || '',
        star_id: userData.n_star_id || '',
        star: userData.c_star_name || '',
        religion: userData.religion || '',
        caste: userData.caste || '',
        education: userData.qualification || '',
        education_id: userData.n_qualification || '',
        employeed_in: userData.employed_in || '',
        employeed_in_id: userData.c_employed_in || '',
        occupation: userData.occupation || '',
        occupation_id: userData.c_current_job || '',
        annual_income: userData.annual_income || '',
        annual_id: userData.c_annual_income || '',
        state_id: userData.n_state_id || '',
        state: userData.c_state || '',
        district_id: userData.n_district_id || '',
        district: userData.c_district || '',
        city_id: userData.n_area_id || '',
        city: userData.c_area_name || '',
        sports_id: userData.c_sports || '',
        sports: userData.sports || '',
        arts_id: userData.c_arts || '',
        arts: userData.arts || '',
        father_name: userData.father_name || '',
        mother_name: userData.mother_name || '',
        father_occupation: userData.c_father_occupation || '',
        mother_occupation: userData.c_mother_occupation || '',
        no_brothers: userData.n_no_brothers || '',
        no_sisters: userData.n_no_sisters || '',
        family_value: userData.c_family_value || '',
        family_type: userData.c_family_type || '',
        family_status: userData.c_family_status || '',
        family_location: userData.c_family_loc || '',
        fam_city_id: userData.n_area_id || '',
        fam_city: userData.c_family_loc || '',
        partner_age: userData.c_bride_age || '',
        partner_height: userData.partner_height || '',
        partner_age_from: userData.age_from || '',
        partner_age_to: userData.age_to || '',
        partner_height_from: userData.height_from || '',
        partner_height_to: userData.height_to || '',
        partner_matrialStatus: userData.partner_matrialStatus || '',
        partner_eatingHabit: userData.partner_eatingHabit || '',
        partner_smokingHabit: userData.partner_smokingHabit || '',
        partner_drinkingHabit: userData.partner_drinkingHabit || '',
        partner_physicalStatus: userData.partner_physical_status || '',
        partner_professioanlStatus: userData.partner_professional_Status || '',

        eating_habit: userData.c_eating_habit || '',
        smoking_habit: userData.c_smoking_habit || '',
        drinking_habit: userData.c_drinking_habit || '',

      });
    }
  }, [userData]);


  const handleEditClickBasicdetails = () => {
    setIsEditingBasicdetails(true);
  };

  const handleSaveClickBasicdetails = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Authentication token missing');
        return;
      }

      // Extract the selected star name from the basicDetails state
      const selectedStarName = basicDetails.star;
      const selectedStarId = basicDetails.star_id
      console.log("selected star : ", selectedStarName);
      console.log("slected star id", selectedStarId);
      const selectedHeightId = basicDetails.height_id;
      const selectedHeightCM = basicDetails.height
      const selectedHeightFeet = basicDetails.ft_height
      console.log("slected height id", selectedHeightId);
      console.log("slected cm id", selectedHeightCM);
      console.log("slected feet id", selectedHeightFeet);

      const response = await instance.put(
        'api/pulayakalyanam/basicDetails_update',
        {
          name: basicDetails.name,
          dob: basicDetails.dateOfBirth,
          age: basicDetails.age,
          height: selectedHeightId,
          gender: basicDetails.gender,
          matrial_status: basicDetails.maritalStatus,
          physical_status: basicDetails.physicalStatus,
          star_id: basicDetails.star_id,
          star_name: selectedStarName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditingBasicdetails(false); // Disable edit mode
        console.log('Profile description updated successfully');


        const updatedUserData = {
          ...userData,
          c_name: basicDetails.name,
          d_dob: basicDetails.dateOfBirth,
          n_age: basicDetails.age,
          c_height: selectedHeightId,
          n_cm: selectedHeightCM,
          n_feet: selectedHeightFeet,
          c_gender: basicDetails.gender,
          c_marital_status: basicDetails.maritalStatus,
          c_physical_status: basicDetails.physicalStatus,
          n_star_id: selectedStarId,
          c_star_name: selectedStarName,
        };
        setBasicDetails((prevBasicDetails) => ({
          ...prevBasicDetails,
          star: selectedStarName,
        }));
        console.log("description", updatedUserData);
        setUserData(updatedUserData);



      } else {
        console.error('Failed to update profile description');
      }
    } catch (error) {
      console.error('Error updating profile description:', error);
    }
  };

  //religion and caste 





  //fetch religion caste


  const [religion, setReligionList] = useState([]);
  const [caste, setCasteList] = useState([]);


  useEffect(() => {
    instance.get('api/pulayakalyanam/admin/show_religion')
      .then(response => {

        setReligionList(response.data);
        console.log('States :', response.data);

      })
      .catch(error => {
        console.error('Error fetching setReligionList:', error);
      });
  }, []);


  useEffect(() => {
    if (formData.religion) {
      console.log('FormData.religion : ', formData.religion);
      fetchCaste(formData.religion);
    }

  }, [formData.religion]);

  const fetchCaste = async (selectedReligion) => {
    try {
      console.log('FormData.religion in :', selectedReligion);
      instance.get(`api/pulayakalyanam/admin/show_caste?religion_id=${selectedReligion}`)
        .then(response => {
          console.log("hai caste");
          setCasteList(response.data);
          console.log('setCasteList', response.data);

        })
    } catch (error) {
      console.error('Error fetching setCasteList:', error);
    }
  };



  const handleReligionChange = (event) => {
    const selectedReligion = event.target.value;
    console.log("selectedReligion", selectedReligion);
    setFormData({ ...formData, religion: selectedReligion });
  };

  const handleCasteChange = (event) => {
    const selectedCaste = event.target.value;
    console.log("selectedCaste", selectedCaste);
    setFormData({ ...formData, caste: selectedCaste });
  };



  const [isEditingReligious, setIsEditingReligious] = useState(false);
  const handleEditClickReligious = () => {
    setIsEditingReligious(true);
  };

  const handleSaveClickReligious = async () => {
    try {
      const selectedReligion = formData.religion;
      const selectedCaste = formData.caste;
      console.log("selected selectedCaste : ", selectedReligion);
      console.log("selected selectedCaste", selectedCaste);
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Authentication token missing');
        return;
      }

      console.log("basicDetailsreligion", basicDetails.religion);
      console.log("basicDetailscaste", basicDetails.caste);
      const response = await instance.put(
        'api/pulayakalyanam/religion_update',
        {
          religion: selectedReligion,
          caste: selectedCaste,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditingReligious(false); // Disable edit mode
        console.log('Profile description updated successfully');

        const updatedUserData = {
          ...userData,
          religion: basicDetails.religion,
          caste: basicDetails.caste,

        };
        console.log("description", updatedUserData);
        setUserData(updatedUserData);
        setBasicDetails((prevBasicDetails) => ({
          ...prevBasicDetails,

          religion: userData.religion,
          caste: userData.caste,

        }));

      } else {
        console.error('Failed to update profile description');
      }
    } catch (error) {
      console.error('Error updating profile description:', error);
    }
  };


  //professional details
  const [isEditingProfessional, setIsEditingProfessional] = useState(false);

  const handleEditClickProfessional = () => {
    setIsEditingProfessional(true);
  };

  const handleSaveClickProfessional = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Authentication token missing');
        return;
      }

      // Extract the selected star name from the basicDetails state
      const selectededucation = basicDetails.education;
      const selectedEmp = basicDetails.employeed_in
      const selectedOccupation = basicDetails.occupation
      const selectedAnnual = basicDetails.annual_income

      const selectededucationID = basicDetails.education_id;
      const selectedEmpID = basicDetails.employeed_in_id
      const selectedOccupationID = basicDetails.occupation_id
      const selectedAnnualID = basicDetails.annual_id
      console.log('Selected emp:', selectededucation)
      console.log('Selected emp:', selectedEmp)
      console.log('Selected occupation:', selectedOccupation);
      console.log('Selected annual income:', selectedAnnual);

      const response = await instance.put(
        'api/pulayakalyanam/professionalDetails_update',
        {
          education: selectededucationID,
          employeed_in: selectedEmpID,
          occupation: selectedOccupationID,
          annual_income: selectedAnnualID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditingProfessional(false); // Disable edit mode
        console.log('Profile description updated successfully');


        const updatedUserData = {
          ...userData,
          qualification: basicDetails.education,
          n_qualification: basicDetails.education_id,
          c_employed_in: basicDetails.employeed_in_id,
          employed_in: basicDetails.employeed_in,
          c_current_job: basicDetails.occupation_id,
          occupation: basicDetails.occupation,
          annual_income: basicDetails.annual_income,
          c_annual_income: basicDetails.annual_id,
        };
        setBasicDetails((prevBasicDetails) => ({
          ...prevBasicDetails,
          education: selectededucation,
          employed_in: selectedEmp,
          occupation: selectedOccupation,
          annual_income: selectedAnnual
        }));
        console.log("description", updatedUserData);
        setUserData(updatedUserData);



      } else {
        console.error('Failed to update profile description');
      }
    } catch (error) {
      console.error('Error updating profile description:', error);
    }
  };

  //location
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const handleEditClickLocation = () => {
    setIsEditingLocation(true);
  };
  const handleSaveClickLocation = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Authentication token missing');
        return;
      }

      // Extract the selected state, district, and city IDs from the formData state
      const selectedState = formData.state;
      const selectedDistrict = formData.district;
      const selectedCity = formData.city;
      console.log("selected State : ", selectedState);
      console.log("selected DISTRICT", selectedDistrict);
      console.log("selected city", selectedCity);

      // Make the PUT request to update the location data
      const response = await instance.put(
        'api/pulayakalyanam/location_update',
        {
          state: selectedState,
          district: selectedDistrict,
          city: selectedCity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditingLocation(false);
        console.log('Profile description updated successfully');

        // Update userData with the selected city
        const updatedUserData = {
          ...userData,
          n_state_id: selectedState,
          n_dist_id: selectedDistrict,
          n_area_id: selectedCity,
          c_state: stateList.find((state) => state.n_state_id === selectedState)?.c_state || '',
          c_district: districtList.find((district) => district.n_dist_id == selectedDistrict)?.c_district || '',
          c_area_name: cityList.find((city) => city.n_area_id == selectedCity)?.c_area_name || '',
        };
        console.log('userData:', userData);
        console.log('updatedUserData:', updatedUserData);
        setUserData(updatedUserData);
        console.log('userData:', userData);

        /*      setBasicDetails(updatedUserData); */
        setBasicDetails((prevBasicDetails) => ({
          ...prevBasicDetails,
          state_id: selectedState,
          district_id: selectedDistrict,
          city_id: selectedCity,
          state: userData.c_state,
          district: userData.c_district,
          city: userData.c_area_name
        }));
        console.log("basic details", basicDetails);

      } else {
        console.error('Failed to update location');

      }
    } catch (error) {
      console.error('Error updating location:', error);
    }
  };

  //family//

  const [isEditingFamily, setIsEditingFamily] = useState(false);


  const handleEditClickFamily = () => {
    setIsEditingFamily(true);
  };

  const handleSaveClickFamily = async () => {
    try {
      const token = localStorage.getItem('authToken')
      if (!token) {
        console.error('Authentication token missing')
        return;

      }
      console.log("famcity", basicDetails.fam_city);
      console.log("famcity", basicDetails.fam_city_id);

      const response = await instance.put(
        'api/pulayakalyanam/familyDetails_update',
        {
          family_value: basicDetails.family_value,
          family_status: basicDetails.family_status,
          father_name: basicDetails.father_name,
          mother_name: basicDetails.mother_name,
          father_occupation: basicDetails.father_occupation,
          mother_occupation: basicDetails.mother_occupation,
          no_brothers: basicDetails.no_brothers,
          no_sisters: basicDetails.no_sisters,
          family_type: basicDetails.family_type,
          family_location: basicDetails.fam_city,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditingFamily(false); // Disable edit mode
        console.log('Profile description updated successfully');

        const updatedUserData = {
          ...userData,
          c_family_type: basicDetails.family_type,
          c_family_value: basicDetails.family_value,
          c_family_status: basicDetails.family_status,
          c_father_occupation: basicDetails.father_occupation,
          c_mother_occupation: basicDetails.mother_occupation,
          n_no_brothers: basicDetails.no_brothers,
          n_no_sisters: basicDetails.no_sisters,
          c_family_loc: basicDetails.fam_city,
          father_name: basicDetails.father_name,
          mother_name: basicDetails.mother_name,
        };
        setBasicDetails((prevBasicDetails) => ({
          ...prevBasicDetails,


        }));
        console.log("description", updatedUserData);
        setUserData(updatedUserData);



      } else {
        console.error('Failed to update profile description');
      }
    } catch (error) {
      console.error('Error updating profile description:', error);
    }
  };


  //habits//
  const [isEditingHabbits, setIsEditingHabbits] = useState(false);
  const handleEditClickHabbits = () => {
    setIsEditingHabbits(true);
  };

  const handleSaveClickHabbits = async () => {
    try {
      const token = localStorage.getItem('authToken')
      if (!token) {
        console.error('Authentication token missing')
        return;
      }


      const response = await instance.put(
        'api/pulayakalyanam/habit_update',
        {
          eating: basicDetails.eating_habit,
          drinking: basicDetails.drinking_habit,
          smoking: basicDetails.smoking_habit,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditingHabbits(false);
        console.log('Profile description updated successfully');

        const updatedUserData = {
          ...userData,
          c_eating_habit: basicDetails.eating_habit,
          c_drinking_habit: basicDetails.drinking_habit,
          c_smoking_habit: basicDetails.smoking_habit,
        };
        setBasicDetails((prevBasicDetails) => ({
          ...prevBasicDetails,


        }));
        console.log("description", updatedUserData);
        setUserData(updatedUserData);



      } else {
        console.error('Failed to update profile description');
      }
    } catch (error) {
      console.error('Error updating profile description:', error);
    }

  };


  const [isEditingSports, setIsEditingSports] = useState(false);
  const handleEditClickSports = () => {
    setIsEditingSports(true);
  };

  const handleSaveClickSports = async () => {

    try {
      const token = localStorage.getItem('authToken')
      if (!token) {
        console.error('Authentication token missing')
        return;
      }


      const response = await instance.put(
        'api/pulayakalyanam/hobbies_update',
        {
          sports: basicDetails.sports_id,
          arts: basicDetails.arts_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditingSports(false);
        console.log('Profile description updated successfully');

        const updatedUserData = {
          ...userData,
          c_sports: basicDetails.sports_id,
          c_arts: basicDetails.arts_id,
          sports: basicDetails.sports,
          arts: basicDetails.arts,
        };
        setBasicDetails((prevBasicDetails) => ({
          ...prevBasicDetails,


        }));
        console.log("description", updatedUserData);
        setUserData(updatedUserData);



      } else {
        console.error('Failed to update profile description');
      }
    } catch (error) {
      console.error('Error updating profile description:', error);
    }
  };

  //partner//
  const [isEditingPartner, setIsEditingPartner] = useState(false);
  const handleEditClickPartner = () => {
    setIsEditingPartner(true);
  };

  const handleSaveClickPartner = async () => {
    try {
      const token = localStorage.getItem('authToken')
      if (!token) {
        console.error('Authentication token missing')
        return;
      }


      const response = await instance.put(
        'api/pulayakalyanam/partner_update',
        {
          age_from: basicDetails.partner_age_from,
          age_to: basicDetails.partner_age_to,
          height_from: basicDetails.partner_height_from,
          height_to: basicDetails.partner_height_to,
          matrial_status: basicDetails.partner_matrialStatus,
          eating_habit: basicDetails.partner_eatingHabit,
          drinking_habit: basicDetails.partner_drinkingHabit,
          smoking_habit: basicDetails.partner_smokingHabit,
          physical_status: basicDetails.partner_physicalStatus,
          professional_details: basicDetails.partner_professioanlStatus
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsEditingPartner(false);
        console.log('Profile description updated successfully');

        const updatedUserData = {
          ...userData,
          age_from: basicDetails.partner_age_from,
          age_to: basicDetails.partner_age_to,
          height_from: basicDetails.height_from,
          height_to: basicDetails.height_to,
          partner_matrialStatus: basicDetails.partner_matrialStatus,
          partner_eatingHabit: basicDetails.partner_eatingHabit,
          partner_smokingHabit: basicDetails.partner_smokingHabit,
          partner_drinkingHabit: basicDetails.partner_drinkingHabit,
          partner_physical_status: basicDetails.partner_physicalStatus,
          partner_professional_Status: basicDetails.partner_professioanlStatus,
        };
        setBasicDetails((prevBasicDetails) => ({
          ...prevBasicDetails,


        }));
        console.log("description", updatedUserData);
        setUserData(updatedUserData);



      } else {
        console.error('Failed to update profile description');
      }
    } catch (error) {
      console.error('Error updating profile description:', error);
    }

  };


  //profile description//

  const [editing, setEditing] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleEditButtonClick = () => {
    setEditing(true);
  };

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSaveButtonClick = () => {
    setEditing(false);
    console.log('Selected value:', selectedValue);
  };


  const [name, setName] = useState(userData ? userData.c_name : '');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  // new matches 

  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          console.error('Authentication token missing');
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/newmatches', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('all matches', response);
        setProfiles(response.data.data); // Assuming profiles are in response.data.data

      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);
  //word count//




  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 500; // Maximum character limit

  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    if (text.length <= maxCharLimit) {
      setProfileDescription(text);
      setCharCount(text.length);
    }
  };


  //fathernae mother name word limit//

  const handleFatherNameChange = (e) => {
    let value = e.target.value;

    // Remove non-alphabet characters and limit the input to 20 characters
    value = value.replace(/[^A-Za-z ]/g, '').slice(0, 20);

    setBasicDetails({
      ...basicDetails,
      father_name: value,
    });
  };


  const handleMotherNameChange = (e) => {
    let value = e.target.value;

    // Remove non-alphabet characters and limit the input to 20 characters
    value = value.replace(/[^A-Za-z ]/g, '').slice(0, 20);

    setBasicDetails({
      ...basicDetails,
      mother_name: value,
    });
  };

  //height//


  //photo  all view
  const [userPhotos, setuserPhotos] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {

          document.cookie = 'connect.sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

          // Clear the local storage item (if you use it for storing the token)
          localStorage.removeItem('authToken');
          navigate('/login')
          return;
        }

        const response = await instance.get('/api/pulayakalyanam/viewallphoto', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('User Data', response);
        setuserPhotos(response.data.data);
        console.log('response all photos : ', response.data.data);

      } catch (error) {
        console.error('Error fetching user newmatches details:', error);

      }
    };

    fetchUserDetails();
  }, []);
  //new//
  //view photo
  const [imageData, setImageData] = useState();
  const [isProfileImageUploaded, setIsProfileImageUploaded] = useState(false);
  const [isFullImageUploaded, setIsFullImageUploaded] = useState(false);
  const [isHeadshotImageUploaded, setIsHeadshotImageUploaded] = useState(false);


  const [imageDatastatus, setImageDatastatus] = useState(null);
  const fetchUserImages = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Authentication token missing');
        navigate('/login');
        return;
      }

      const response = await instance.get(
        'api/pulayakalyanam/photo/viewuploadPhoto',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const fetchedImagesData = response.data.data
      setImageData(fetchedImagesData);
      setImageDatastatus(fetchedImagesData[0].p_status)
      if (imageDatastatus === 'N') {
        console.log("hai");
      }

      if (fetchedImagesData.length >= 3) {
        const profileImageUrl = fetchedImagesData[0].c_profile_image
        const fullImageUrl = fetchedImagesData[1].c_profile_image
        const headshotImageUrl = fetchedImagesData[2].c_profile_image



      }
      console.log("p_status of the first image:", fetchedImagesData[0].p_status);



    } catch (error) {
      console.error('Error fetching user images:', error);
    }
  };

  useEffect(() => {
    fetchUserImages();
  }, []);


  return (
    <div className='matches-background'>
      <Navbars></Navbars>
      <Container >

        <MDBRow className='profileedit-matches-all'>
          <MDBCol size='6' sm='3' className='columns-container'>

            <MDBContainer>
              <MDBRow>
                <MDBCol>

                  <Carousel className='similar-photos'>



                    <div className='editprofile-data img-fluid '>
                      {imageData ? (

                        <img
                          src={imageData ? imageData[0].c_profile_image : 'Addphoto-hr.png'}
                          alt="Profile"
                          className={`img-fluid searchemployee upload upload-image ${imageData[1]?.p_status === 'N' ? 'image-blur' : ''}`}
                        />

                      ) : (
                        <img src='default-photo-url.png' className='img-fluid searchemployee' alt='Default'  />
                      )}
                    </div>
                    <div className='editprofile-data img-fluid '>
                      {imageData ? (

                        <img
                          src={imageData ? imageData[1].c_profile_image : 'Addphoto-hr.png'}
                          alt="Profile"
                          className={`img-fluid searchemployee upload upload-image ${imageData[1]?.p_status === 'N' ? 'image-blur' : ''}`}
                        />
                      ) : (
                        <img src='default-photo-url.png' className='img-fluid searchemployee' alt='Default'  />
                      )}
                    </div>
                    <div className='editprofile-data img-fluid '>
                      {imageData ? (

                        <img
                          src={imageData ? imageData[2].c_profile_image : 'Addphoto-hr.png'}
                          alt="Profile"
                          className={`img-fluid searchemployee upload upload-image ${imageData[2]?.p_status === 'N' ? 'image-blur' : ''}`}
                        />
                      ) : (
                        <img src='default-photo-url.png' className='img-fluid searchemployee' alt='Default' />
                      )}
                    </div>
                  </Carousel>
                  <div className=' editprofiletyping similer-profile-profileedit'>
                    <p className='similer-properties'>Similar Profiles</p><hr></hr>

                    {/* {profiles.length > 0 ? (
      profiles.map((profile) => (

<MDBRow className='similer-profile-profileedit'>
      <MDBCol size='6' sm='4' >
      <img src={profile.c_profile_image} className='similer-images img-fluid'></img>
      </MDBCol>
      <MDBCol size='6' sm='8' className='editprofile-only'>
      <p className='editprofile-name'>{profile.c_name}</p>
      <p className='editprofile-id'>{profile.c_usercode }|Profile Created by {profile. profile_for },<br></br>
      {profile. n_age }Yrs, {profile.n_feet || profile.n_cm  ? `${profile.n_feet || 'height'} / ${profile.n_cm || 'height'} cm`  : 'height not defined'}</p>
       <a className='editprofile-seemore' href={`Profiledetails/${profile.c_usercode}`}>See More</a> */}
                    <div className='scrollable-container '>
                      {profiles.length > 0 ? (
                        profiles.map((profile) => (

                          <MDBRow className='similer-profile-profileedit'>
                            <MDBCol size='6' sm='4' >

                              <img src={profile.c_profile_image} className='similer-images img-fluid'></img>
                              <img src='/default-photo-url.png' className='similer-images img-fluid' />
                            </MDBCol>
                            <MDBCol size='6' sm='8' className='editprofile-only'>
                              <p className='editprofile-name'>{profile.c_name}</p>
                              <p className='editprofile-id'>{profile.c_usercode}|Profile Created by {profile.profile_for},<br></br>
                                {profile.n_age}Yrs, {profile.n_feet || profile.n_cm ? `${profile.n_feet || 'height'} / ${profile.n_cm || 'height'} cm` : 'height not defined'}</p>
                              <a className='editprofile-seemore' href={`Profiledetails/${profile.c_usercode}`}>See More</a>

                              {/* <p className='editprofile-seemore'>See More</p> */}
                            </MDBCol>
                          </MDBRow>


                        ))
                      ) : (
                        <p className='loading'>No similar profiles</p>
                      )}
                    </div>


                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
          <MDBCol size='6' sm='9' className='profile-edit-danial'>
            <p className='profile-editheading'>Profile Edit</p>
            {userData && (
              <>
                <p className='danils'>{userData.c_name}<span className='online'><img src='../Images/online.png'></img></span><span className='active-online'><span className="dot"></span> online</span></p>

                <MDBRow className='profile-row'>
                  <MDBCol >
                    <p className='mother-tongue'>{userData.n_age} Yrs | {userData.c_usercode}</p>
                    <p className='mother-tongue'>   {userData.n_cm ? `${userData.n_cm} cm / ${userData.n_feet}` : 'height not defined'}</p>

                    <p className='mother-tongue'>{userData.religion || 'Religion not defined'} / {userData.caste || 'Caste not defined'}</p>
                    <p className='mother-tongue'>Occupation in {userData.occupation || 'Not defined'}</p>
                  </MDBCol>
                  <MDBCol >
                    <p className='mother-tongue'>Star is {userData.c_star_name || 'Not defined'}</p>

                    <p className='mother-tongue'>Lives in {userData.c_district || 'Not defined'}</p>
                    <p className='mother-tongue'>Studied {userData.qualification || 'Not defined'}</p>
                  </MDBCol>
                </MDBRow>
              </>
            )}
            <div className='profile-editprofile '>
              <div className='editing-religious-save'>
                {isEditingDescription ? (

                  <button onClick={handleSaveClickDescription}>Save

                  </button>
                ) : (
                  <button onClick={handleEditClickDescription}>
                    <MDBIcon far icon="edit" />Edit
                  </button>
                )}
              </div>

              <p className='profile-description'>Profile Description</p>
              {userData && (
                <>


                  {isEditingDescription ? (
                    <Form>

                      <textarea
                        className='profile-description-textarea'
                        value={profileDescription}
                        onChange={handleDescriptionChange}
                        style={{
                          maxWidth: '100%',
                          overflow: 'auto',
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word',
                          maxHeight: '200px',
                        }}
                      />

                      {/* {charCount > maxCharLimit && (
                        <p className="error-message">Character limit exceeded (max 500 characters).</p>
                      )} */}
                      <p>Character Count: {charCount} / {maxCharLimit}</p>
                    </Form>
                  ) : (

                    <p className='profile-des ' >{userData.c_prof_description || 'Not defined'}</p>

                  )}



                </>)}
            

              <MDBRow>

                <p className='profile-description'>Basic Details</p>
                <div className='editing-religious'>
                  {isEditingBasicdetails ? (
                    <button onClick={handleSaveClickBasicdetails}>Save

                    </button>
                  ) : (
                    <button onClick={handleEditClickBasicdetails}>
                      <MDBIcon far icon="edit" />Edit
                    </button>
                  )}
                </div>

                <MDBCol className='profile-edit-fulldetails col-12 col-md-6'>

                  <Form>



                    {userData && (
                      <>
                        {isEditingBasicdetails ? (
                          <p className='profileedit-name'>
                            Name <span className='namespace-more'>:</span>{' '}
                            <input
                              type='text'
                              className='namespace editing-input' // Add a CSS class for styling in editing mode
                              value={basicDetails.name}
                              onChange={(e) => {
                                setBasicDetails({
                                  ...basicDetails,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </p>
                        ) : (
                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Name
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}  {userData.c_name}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {userData && (
                      <>
                        {isEditingBasicdetails ? (
                          <p className='basicdetails-date'>
                            Date of birth
                            <span className='datespaceonly'>:
                              <DatePicker
                                placeholderText=" dd-mm-yyyy"
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="dd-MM-yyyy"
                                maxDate={maxDate}
                                minDate={minDate}
                                showYearDropdown
                                yearDropdownItemNumber={90}
                                value={basicDetails.dateOfBirth}
                              />
                            </span>
                          </p>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Date of birth
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && (userData.d_dob) || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>
                    )}


                    {userData && (
                      <>
                        {isEditingBasicdetails ? (
                          <p className='basicdetails'>
                            Age <span className='agespace'>: {basicDetails.age} Years </span>
                          </p>


                        ) : (
                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Age
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.n_age || 'Not defined'} Years
                            </div>
                          </div>

                        )}
                      </>)}

                    {/* height */}

                    {isEditingBasicdetails ? (

                      <select
                        id="height"
                        name="height"
                        className='SelectBoxs-searchpage-profileedit'

                        style={{ width: 350 }}
                        value={basicDetails.height_id}  // Initialize with basicDetails.height_id
                        onChange={(e) => {
                          const selectedHeightId = e.target.value;
                          const selectedHeight = height.find(height => height.n_slno == selectedHeightId);
                          console.log('selectedHeight is:', selectedHeight);
                          console.log('selectedHeight is:', selectedHeight.n_feet);

                          setBasicDetails({
                            ...basicDetails,
                            height_id: selectedHeightId,
                            height: selectedHeight ? selectedHeight.n_cm : '',
                            ft_height: selectedHeight ? selectedHeight.n_feet : '',

                          });
                        }}
                      >
                        {height.map(height => (
                          <option key={height.n_slno} value={height.n_slno}>
                            {`${height.n_cm} cm / ${height.n_feet}`}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Height
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {basicDetails.height} cm / {basicDetails.ft_height}
                        </div>
                      </div>

                    )}





                  </Form>
                </MDBCol>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>



                    {userData && (
                      <>
                        {isEditingBasicdetails ? (
                          <select id="gender" name="gender" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.gender}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                gender: e.target.value,
                              });
                            }}>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Gender
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}  {userData && userData.c_gender || 'Not defined'}
                            </div>
                          </div>
                        )}
                      </>)}

                    {userData && (
                      <>
                        {isEditingBasicdetails ? (
                          <select id="maritalStatus" name="maritalStatus" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.maritalStatus}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                maritalStatus: e.target.value,
                              });
                            }}>

                            <option value="Never Married">Never Married</option>
                            <option value="widowed">widowed</option>
                            <option value="Divorced">Divorced</option>
                            <option value="Single">Single</option>
                            <option value="Seperated">Seperated</option>
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Marital Status
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.c_marital_status || 'Not defined'}
                            </div>
                          </div>
                        )}
                      </>)}

                    {userData && (
                      <>
                        {isEditingBasicdetails ? (
                          <select id="physicalStatus" name="physicalStatus" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.physicalStatus}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                physicalStatus: e.target.value,

                              });
                            }}>
                            <option value="Normal">Normal</option>
                            <option value="Slim">Slim</option>
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Physical Status
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.c_physical_status || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}
                    {userData && (
                      <>
                        {isEditingBasicdetails ? (
                          <select
                            id="star"
                            name="star"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.star_id}
                            onChange={(e) => {
                              const selectedStarId = e.target.value;
                              console.log('Selected Star ID:', selectedStarId);
                              /* const selectedStar = star.find(star => star.pn_sid === selectedStarId);
                              console.log('Selected Star:', selectedStar); */
                              const selectedStar = star.find(star => star.pn_sid == selectedStarId);

                              console.log('Selected Star:', selectedStar);


                              setBasicDetails({
                                ...basicDetails,
                                star_id: selectedStarId,
                                star: selectedStar ? selectedStar.c_star_name : '',

                              });

                            }}

                          >
                            <option key="" value="" disabled>Star</option>
                            {star.map(star => (
                              <option key={star.pn_sid} value={star.pn_sid}>
                                {star.c_star_name}
                              </option>
                            ))}
                          </select>
                        ) : (


                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Star
                            </div>
                            <div style={{ flex: 1 }}>
                              : {''}{basicDetails.star_id ? basicDetails.star : userData.c_star_name}
                            </div>
                          </div>
                        )}
                      </>
                    )}


                  </Form>
                </MDBCol>

              </MDBRow>

              <hr></hr>

              <MDBRow>

                <div className='editing-religious'>
                  {isEditingReligious ? (
                    <button onClick={handleSaveClickReligious}>Save

                    </button>
                  ) : (
                    <button onClick={handleEditClickReligious}>
                      <MDBIcon far icon="edit" /> Edit
                    </button>
                  )}
                </div>
                <p className='profile-description'>Religious Details</p>

                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>
                    {isEditingReligious ? (
                      <select id="religion" name="religion"
                        className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                        value={formData.religion}
                        onChange={handleReligionChange}
                      > <option value="" disabled selected hidden>Religion</option>
                        {religion.map(religion => (
                          <option key={religion.id} value={religion.id}>
                            {religion.religion}
                          </option>
                        ))}</select>
                    ) : (

                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Religion
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {basicDetails.religion}
                        </div>
                      </div>

                    )}
                  </Form>
                </MDBCol>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>
                    {isEditingReligious ? (
                      <select id="caste" name="caste" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                        value={formData.caste}
                        onChange={handleCasteChange}
                      >
                        <option value="" disabled selected hidden>caste</option>
                        {caste.map(caste => (
                          <option key={caste.id} value={caste.id}>
                            {caste.caste}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div className='annual-income' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }} className="full-bolder">
                          Caste
                        </div>
                        <div style={{ flex: 1 }}>
                          :{''} {basicDetails.caste}
                        </div>
                      </div>

                    )}
                  </Form>
                </MDBCol>

              </MDBRow><hr></hr>

              <MDBRow>
                <div className='editing-religious'>
                  {isEditingProfessional ? (
                    <button onClick={handleSaveClickProfessional}>Save

                    </button>
                  ) : (
                    <button onClick={handleEditClickProfessional}>
                      <MDBIcon far icon="edit" /> Edit
                    </button>
                  )}
                </div>
                <p className='profile-description'>Professional Details</p>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>
                    {userData && (
                      <>
                        {isEditingProfessional ? (
                          <select
                            id="education"
                            name="education"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.education_id}
                            onChange={(e) => {
                              const selectedEducationId = e.target.value;
                              console.log('Selected Education ID:', selectedEducationId);

                              const selectedEducation = education.find(education => education.qid == selectedEducationId);

                              console.log('Selected Star:', selectedEducation);


                              setBasicDetails({
                                ...basicDetails,
                                education_id: selectedEducationId,
                                education: selectedEducation ? selectedEducation.qualification : '',

                              });

                            }}

                          >
                            <option key="" value="" disabled>Education</option>
                            {education.map(education => (
                              <option key={education.qid} value={education.qid}>
                                {education.qualification}
                              </option>
                            ))}
                          </select>
                        ) : (


                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Education
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {basicDetails.education_id ? basicDetails.education : userData.qualification}
                            </div>
                          </div>

                        )}
                      </>
                    )}


                    {userData && (
                      <>
                        {isEditingProfessional ? (
                          <select
                            id="employedin"
                            name="employedin"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.employeed_in_id}
                            onChange={(e) => {
                              const selectedEmpId = e.target.value;
                              console.log('Selected Emp ID:', selectedEmpId);

                              const selectedEmp = employeed_in.find(employeed_in => employeed_in.emp_id == selectedEmpId);

                              console.log('Selected empssss:', selectedEmp);

                              setBasicDetails((prevDetails) => ({
                                ...prevDetails,
                                employeed_in_id: selectedEmpId,
                                employeed_in: selectedEmp ? selectedEmp.employed_in : '',
                              }));
                            }}
                          >
                            <option value="" disabled>Employed in</option>

                            {/* Remove the selected attribute from here */}
                            {employeed_in.map(employeed_in => (
                              <option key={employeed_in.emp_id} value={employeed_in.emp_id}>
                                {employeed_in.employed_in}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Employed in
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {basicDetails.n_id ? basicDetails.employed_in : userData.employed_in}

                            </div>
                          </div>
                        )}
                      </>
                    )}


                  </Form>
                </MDBCol>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>
                    {userData && (
                      <>
                        {isEditingProfessional ? (
                          <select
                            id="occupation"
                            name="occupation"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.occupation_id}
                            onChange={(e) => {
                              const selectedOccupationId = e.target.value;
                              console.log('Selected Occupation ID:', selectedOccupationId);
                              const selectedOccupation = occupation.find(occupation => occupation.occid == selectedOccupationId);

                              console.log('Selected occupation:', selectedOccupation);


                              setBasicDetails({
                                ...basicDetails,
                                occupation_id: selectedOccupationId,
                                occupation: selectedOccupation ? selectedOccupation.occupation : '',

                              });

                            }}

                          >
                            <option key="" value="" disabled>Occupation</option>
                            {occupation.map(occupation => (
                              <option key={occupation.occid} value={occupation.occid}>
                                {occupation.occupation}
                              </option>
                            ))}
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Occupation
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {basicDetails.occupation_id ? basicDetails.occupation : userData.occupation}
                            </div>
                          </div>

                        )}
                      </>
                    )}

                    {userData && (
                      <>
                        {isEditingProfessional ? (
                          <select
                            id="annualincome"
                            name="annualincome"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.annual_id}
                            onChange={(e) => {
                              const selectedAnnualId = e.target.value;
                              console.log('Selected auunual ID:', selectedAnnualId);
                              const selectedAnnual = annual_income.find(annual_income => annual_income.id == selectedAnnualId);

                              console.log('Selected annual income sw:', selectedAnnual);


                              setBasicDetails({
                                ...basicDetails,
                                annual_id: selectedAnnualId,
                                annual_income: selectedAnnual ? selectedAnnual.annual_income : '',

                              });

                            }}

                          >
                            <option key="" value="" disabled>Annual income</option>
                            {annual_income.map(annual_income => (
                              <option key={annual_income.id} value={annual_income.id}>
                                {annual_income.annual_income}
                              </option>
                            ))}
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Annual income
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {basicDetails.annual_id ? basicDetails.annual_income : userData.annual_income}
                            </div>
                          </div>

                        )}
                      </>
                    )}


                  </Form>
                </MDBCol>
              </MDBRow><hr></hr>


              {/* Location edit */}
              <MDBRow>

                <div className='editing-religious'>
                  {isEditingLocation ? (
                    <button onClick={handleSaveClickLocation}>Save

                    </button>
                  ) : (
                    <button onClick={handleEditClickLocation}>
                      <MDBIcon far icon="edit" /> Edit
                    </button>
                  )}
                </div>
                <p className='profile-description'>Location Details</p>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>
                    {userData && (
                      <>

                        {isEditingLocation ? (

                          <select
                            id="state"
                            name="state"
                            className="SelectBoxs-searchpage-profileedit"
                            style={{ width: 350 }}
                            value={formData.state}
                            onChange={handleStateChange}

                          >
                            <option value="" disabled>State</option>
                            {stateList.map((state) => (
                              <option key={state.n_state_id} value={state.n_state_id}>
                                {state.c_state}
                              </option>

                            ))}
                          </select>

                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              State
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {basicDetails.state}
                            </div>
                          </div>


                        )}
                      </>
                    )}

                    {userData && (
                      <>
                        {isEditingLocation ? (
                          <select
                            id="district"
                            name="district"
                            className="SelectBoxs-searchpage-profileedit"
                            style={{ width: 350 }}
                            value={formData.district}
                            onChange={handleDistrictChange}
                          >
                            <option value="" disabled> District</option>
                            {districtList.map((district) => (
                              <option key={district.n_dist_id} value={district.n_dist_id}>
                                {district.c_district} {/* Display the district name */}
                              </option>
                            ))}
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>

                            <div style={{ flex: 1 }} className="full-bolder">
                              District
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {basicDetails.district}
                            </div>
                          </div>

                        )}

                      </>
                    )}
                  </Form>
                </MDBCol>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>
                    {userData && (
                      <>
                        {isEditingLocation ? (
                          <select
                            id="city"
                            name="city"
                            className="SelectBoxs-searchpage-profileedit"
                            style={{ width: 350 }}
                            value={formData.city}
                            onChange={handleCityChange}
                          >
                            <option value="" disabled>City</option>
                            {cityList.map((city) => (
                              <option key={city.n_area_id} value={city.n_area_id}>
                                {city.c_area_name}
                              </option>
                            ))}
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              City
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {basicDetails.city}
                            </div>
                          </div>

                        )}
                      </>
                    )}
                  </Form>
                </MDBCol>
              </MDBRow><hr></hr>


              {/* family details */}
              <MDBRow>
                <div className='editing-religious'>
                  {isEditingFamily ? (
                    <button onClick={handleSaveClickFamily}>Save

                    </button>
                  ) : (
                    <button onClick={handleEditClickFamily}>
                      <MDBIcon far icon="edit" /> Edit
                    </button>
                  )}
                </div>
                <p className='profile-description'>Family Details</p>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>


                    {userData && (
                      <>
                        {isEditingFamily ? (
                          <select id="family_value" name="family_value" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.family_value}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                family_value: e.target.value,
                              });
                            }}>
                            <option value="" > Family value</option>

                            <option value="Orthodox">Orthodox</option>
                            <option value="Moderate">Moderate</option>
                            <option value="Traditional">Traditional</option>
                            <option value="Liberal">Liberal</option>
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Family value
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.c_family_value || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}




                    {userData && (
                      <>
                        {isEditingFamily ? (
                          <input
                            type="text"
                            id="fathername"
                            name="fathername"
                            className='TextInput-searchpage-profileedit'
                            placeholder="Enter Father name"
                            value={basicDetails.father_name}
                            onChange={handleFatherNameChange}
                          />
                        ) : (
                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Father Name
                            </div>
                            <div style={{ flex: 1 }}>
                              :{' '}
                              {basicDetails.father_name ? basicDetails.father_name : 'Father Name'}
                            </div>
                          </div>
                        )}
                      </>

                    )}

                    {/*  {userData && (
    <>
{isEditingFamily ? (
  
  <input
    type="text"
    id="fatheroccupation"
    style={{
      width: 350,
      border: 'none',
      marginBottom: '3%', // Add this line to set the bottom margin
    }}
    name="fatheroccupation"
    className='TextInput-searchpage-profileedit' 
    placeholder="Enter Father's Occupation"
    value={basicDetails.father_occupation} 
          onChange={(e) => {
            
            setBasicDetails({
              ...basicDetails,
              father_occupation: e.target.value,
            });
          }}
  />
) : (

  <div className='annual-income' style={{ display: 'flex' }}>
    <div style={{ flex: 1 }} className="full-bolder">
  Father's Occupation :
  </div>
  <div style={{ flex: 1 }}>
   :{''} {userData && userData.c_father_occupation || 'Not defined'}
  </div>
</div>
 
)}
  </>)} */}




                    {userData && (
                      <>
                        {isEditingFamily ? (
                          <select id="fatheroccupation" name="fatheroccupation" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.father_occupation}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                father_occupation: e.target.value,
                              });
                            }}>
                            <option value="" disabled>Father's Occupation</option>

                            <option value="Goverment" >Goverment</option>
                            <option value="Business" >Business</option>
                            <option value="Defence" >Defence</option>
                            <option value="Self Employed" >Self Employed</option>
                            <option value="Private" >Private</option>
                            <option value="Not Working" >Not Working</option>
                          </select>
                        ) : (


                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Father's Occupation
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.c_father_occupation || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}



                    {userData && (
                      <>
                        {isEditingFamily ? (
                          <select
                            id="no_brothers"
                            name="no_brothers"
                            className="SelectBoxs-searchpage-profileedit"
                            style={{ width: 350 }}
                            value={basicDetails.no_brothers}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                no_brothers: e.target.value,
                              });
                            }}
                          >
                            <option value="" >No. of Brothers</option>
                            <option value="none">None</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                        ) : (
                          <div className="annual-income" style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              No. of Brothers
                            </div>
                            <div style={{ flex: 1 }}>
                              : {''} {userData && userData.n_no_brothers || 'Not defined'}
                            </div>
                          </div>
                        )}
                      </>
                    )}



                    {userData && (
                      <>
                        {isEditingFamily ? (
                          <select id="family_type" name="family_type" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.family_type}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                family_type: e.target.value,
                              });
                            }}>
                            <option value="" disabled> Family Type</option>
                            <option value="Nuclear">Nuclear</option>
                            <option value="Joint">Joint</option>
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Family Type
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.c_family_type || 'Not defined'}
                            </div>
                          </div>


                        )}
                      </>)}



                  </Form>
                </MDBCol>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>



                    {userData && (
                      <>
                        {isEditingFamily ? (
                          <select id="family_status" name="family_status" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.family_status}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                family_status: e.target.value,
                              });
                            }}>
                            <option value="" disabled>    Family Status </option>
                            <option value="Rich Class" >Rich Class</option>
                            <option value="Upper Class" >Upper Class</option>
                            <option value="Middle Class" >Middle Class</option>
                            <option value="Lower Class" >Lower Class</option>
                          </select>
                        ) : (


                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Family Status
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.c_family_status || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}

                    {userData && (
                      <>
                        {isEditingFamily ? (
                          <input
                            type="text"
                            id="mothername"
                            name="mothername"
                            className='TextInput-searchpage-profileedit'
                            placeholder="Enter Mother name"
                            value={basicDetails.mother_name}
                            onChange={handleMotherNameChange}
                          />
                        ) : (
                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Mother Name
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.mother_name || 'Not defined'}
                            </div>
                          </div>
                        )}
                      </>
                    )}


                    {/* {userData && (
    <>
{isEditingFamily ? (
  <input
    type="text"
    id="motheroccupation"
    style={{
      width: 350,
      border: 'none',
      marginBottom: '3%', // Add this line to set the bottom margin
    }}
    name="motheroccupation"
    className='TextInput-searchpage-profileedit' 
    placeholder="Enter Mothers's Occupation"
    value={basicDetails.mother_occupation} 
          onChange={(e) => {
            
            setBasicDetails({
              ...basicDetails,
              mother_occupation: e.target.value,
            });
          }}
  />
) : (
  <div className='annual-income' style={{ display: 'flex' }}>
          <div style={{ flex: 1 }} className="full-bolder">
        Mother's occupation :
        </div>
        <div style={{ flex: 1 }}>
         :{''}  {userData && userData.c_mother_occupation || 'Not defined'}
        </div>
      </div>

)}
  </>)} */}

                    {userData && (
                      <>
                        {isEditingFamily ? (
                          <select id="motheroccupation" name="motheroccupation" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.mother_occupation}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                mother_occupation: e.target.value,
                              });
                            }}>
                            <option value="" disabled>    Mother's Occupation  </option>
                            <option value="Goverment" >Goverment</option>
                            <option value="Business" >Business</option>
                            <option value="Defence" >Defence</option>
                            <option value="Self Employed" >Self Employed</option>
                            <option value="Private" >Private</option>
                            <option value="House Wife" >House Wife</option>
                            <option value="Not Working" >Not Working</option>
                          </select>
                        ) : (


                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Mother's Occupation
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.c_mother_occupation || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}


                    {userData && (
                      <>
                        {isEditingFamily ? (
                          <select
                            id="no_sisters"
                            name="no_sisters"
                            className="SelectBoxs-searchpage-profileedit"
                            style={{ width: 350, marginBottom: '3%' }}
                            value={basicDetails.no_sisters}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                no_sisters: e.target.value,
                              });
                            }}
                          >
                            <option value="" disabled>No. of Sisters</option>
                            <option value="none">None</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </select>
                        ) : (
                          <div className="annual-income" style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              No. of Sisters
                            </div>
                            <div style={{ flex: 1 }}>
                              : {''} {userData && userData.n_no_sisters || 'Not defined'}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {userData && (
                      <>
                        {isEditingFamily ? (
                          <select
                            id="cities"
                            name="cities"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.fam_city_id} // Set value to basicDetails.fam_city_id
                            onChange={(e) => {
                              const selectedfamCityId = e.target.value;



                              var selectedfamCity;

                              cities.forEach(city => {

                                if (city.n_area_id == selectedfamCityId) {
                                  selectedfamCity = city;
                                }

                              });



                              // console.log(`selctfamCity : ${JSON.stringify(selectedfamCity)}`);
                              console.log(`selctfamCityabc : ${selectedfamCity}`);


                              console.log('Selected fam city:', selectedfamCity.c_area_name);


                              setBasicDetails({
                                ...basicDetails,
                                fam_city_id: selectedfamCityId,
                                fam_city: selectedfamCity?.c_area_name,
                              });
                            }}
                          >
                            <option value="" disabled>Family Location</option>
                            {cities.map(city => (
                              <option key={city.n_area_id} value={city.n_area_id}>
                                {city.c_area_name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Family Location
                            </div>
                            <div style={{ flex: 1 }}>
                              :{' '}
                              {userData && userData.c_family_loc || 'Not defined'}
                            </div>
                          </div>
                        )}
                      </>
                    )}



                  </Form>
                </MDBCol>
              </MDBRow><hr></hr>


              {/* habit */}
              <MDBRow>
                <div className='editing-religious'>
                  {isEditingHabbits ? (
                    <button onClick={handleSaveClickHabbits}>Save

                    </button>
                  ) : (
                    <button onClick={handleEditClickHabbits}>
                      <MDBIcon far icon="edit" /> Edit
                    </button>
                  )}
                </div>
                <p className='profile-description'>Habits</p>

                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>

                    {userData && (
                      <>
                        {isEditingHabbits ? (
                          <select id="eating" name="eating" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.eating_habit}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                eating_habit: e.target.value,
                              });
                            }}>
                            <option key="" value="" disabled> Eating Habits:</option>

                            <option value="Vegetarian">Vegetarian</option>
                            <option value="Non vegetarian">Non vegetarian</option>
                            <option value="Eggetarian">Eggetarian</option>
                            <option value="Does not matter">Does not matter</option>
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Eating Habits
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}  {userData && userData.c_eating_habit || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}


                    {userData && (
                      <>
                        {isEditingHabbits ? (
                          <select id="smoke" name="smoke" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.smoking_habit}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                smoking_habit: e.target.value,
                              });
                            }}>
                            <option key="" value="" disabled>   Smoking Habits:</option>
                            <option value="Does not matter">Does not matter</option>
                            <option value="Never smokes">Never smokes</option>
                            <option value="Smoke occasionally">Smoke occasionally</option>

                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Smoking Habits
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}  {userData && userData.c_smoking_habit || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}



                  </Form>
                </MDBCol>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>
                    {userData && (
                      <>
                        {isEditingHabbits ? (
                          <select id="drink" name="drink" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.drinking_habit}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                drinking_habit: e.target.value,
                              });
                            }}>
                            <option value="" disabled>Driking Habits:</option>
                            <option value="Does not matter" >Does not matter</option>
                            <option key="" value="Drinks socially" >Drinks socially</option>
                            <option key="" value="Never drinks" >Never drinks</option>

                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Driking Habits
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}   {userData && userData.c_drinking_habit || 'Not defined'}
                            </div>
                          </div>


                        )}
                      </>)}

                  </Form>
                </MDBCol>
              </MDBRow><hr></hr>

              {/* hobbies */}
              <MDBRow>
                <div className='editing-religious'>
                  {isEditingSports ? (
                    <button onClick={handleSaveClickSports}>Save

                    </button>
                  ) : (
                    <button onClick={handleEditClickSports}>
                      <MDBIcon far icon="edit" /> Edit
                    </button>
                  )}
                </div>
                <p className='profile-description'>Hobbies</p>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>


                    {userData && (
                      <>
                        {isEditingSports ? (
                          <select
                            id="sports"
                            name="sports"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.sports_id}
                            onChange={(e) => {
                              const selectedStarId = e.target.value;
                              console.log('Selected Star ID:', selectedStarId);
                              /* const selectedStar = star.find(star => star.pn_sid === selectedStarId);
                              console.log('Selected Star:', selectedStar); */
                              const selectedStar = sports.find(sports => sports.id == selectedStarId);

                              console.log('Selected Star:', selectedStar);


                              setBasicDetails({
                                ...basicDetails,
                                sports_id: selectedStarId,
                                sports: selectedStar ? selectedStar.sports : '',

                              });

                            }}

                          >
                            <option key="" value="" disabled>Sports</option>
                            {sports.map(sports => (
                              <option key={sports.id} value={sports.id}>
                                {sports.sports}
                              </option>
                            ))}
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Sports
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}  {basicDetails.sports_id ? basicDetails.sports : userData.sports}
                            </div>
                          </div>

                        )}
                      </>
                    )}


                  </Form>
                </MDBCol>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>
                    {userData && (
                      <>
                        {isEditingSports ? (
                          <select
                            id="arts"
                            name="arts"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.arts_id}
                            onChange={(e) => {
                              const selectedStarId = e.target.value;
                              console.log('Selected Star ID:', selectedStarId);
                              /* const selectedStar = star.find(star => star.pn_sid === selectedStarId);
                              console.log('Selected Star:', selectedStar); */
                              const selectedStar = arts.find(arts => arts.id == selectedStarId);

                              console.log('Selected Star:', selectedStar);


                              setBasicDetails({
                                ...basicDetails,
                                arts_id: selectedStarId,
                                arts: selectedStar ? selectedStar.arts : '',

                              });

                            }}

                          >
                            <option key="" value="" disabled>Arts</option>
                            {arts.map(arts => (
                              <option key={arts.id} value={arts.id}>
                                {arts.arts}
                              </option>
                            ))}
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Arts
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}   {basicDetails.arts_id ? basicDetails.arts : userData.arts}
                            </div>
                          </div>

                        )}
                      </>
                    )}

                  </Form>
                </MDBCol>
              </MDBRow>
              {/*  <Button href='/' className='searchButtons-profileedit' type="submit">
        Save
        </Button> */}
            </div>




            {/*  partner edit details */}

            <p className='partner-preference-profileedit'>Partner Preferences Matches</p>
            <div className='importentdetails-editprofile'>
              <MDBRow >

                <div className='editing-religious-save'>
                  {isEditingPartner ? (
                    <button onClick={handleSaveClickPartner}>Save

                    </button>
                  ) : (
                    <button onClick={handleEditClickPartner}>
                      <MDBIcon far icon="edit" /> Edit
                    </button>
                  )}
                </div>
                <p className='profile-description'>Basic Details</p>
                <MDBCol size='6' sm='6' className='partner-profileedit' >
                  <Form>

                    {userData && (
                      <>
                        {isEditingPartner ? (
                          <select
                            id="age"
                            name="age"
                            className="SelectBoxs-searchpage-profileedit"
                            style={{ width: 350 }}
                            value={basicDetails.partner_age_from}
                            onChange={(e) => {
                              const selectedAgeFrom = parseInt(e.target.value);
                              const selectedAgeTo = basicDetails.partner_age_to;

                              setBasicDetails({
                                ...basicDetails,
                                partner_age_from: selectedAgeFrom,
                                partner_age_to: selectedAgeFrom + 1, // Age To is set to Age From + 1
                              });
                            }}
                          >

                            <option key="" value="" disabled>
                              Age (From)
                            </option>
                            {Array.from({ length: 83 }, (_, index) => (
                              <option key={index} value={18 + index}>
                                {18 + index} years
                              </option>
                            ))}
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Age From
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}   {userData && userData.age_from || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}


                    {userData && (
                      <>
                        {isEditingPartner ? (
                          <select
                            id="age"
                            name="age"
                            className="SelectBoxs-searchpage-profileedit"
                            style={{ width: 350 }}
                            value={basicDetails.partner_age_to}
                            onChange={(e) => {
                              const selectedAgeTo = parseInt(e.target.value);

                              setBasicDetails({
                                ...basicDetails,
                                partner_age_to: selectedAgeTo,
                              });
                            }}
                          >

                            <option key="" value="">
                              Age To
                            </option>
                            {Array.from({ length: 83 - basicDetails.partner_age_from }, (_, index) => (
                              <option key={index} value={basicDetails.partner_age_from + 1 + index}>
                                {basicDetails.partner_age_from + 1 + index} years
                              </option>
                            ))}
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Age To
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}   {userData && userData.age_to || 'Not defined'}
                            </div>
                          </div>

                        )}

                      </>)}


                    {userData && (
                      <>
                        {isEditingPartner ? (
                          <select id="maritalStatus" name="maritalStatus" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.partner_matrialStatus}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                partner_matrialStatus: e.target.value,
                              });
                            }}>
                            <option key="" value="Marital Status" disabled> Marital Status</option>
                            <option value="Does not matter">Does not matter</option>
                            <option value="Never Married">Never Married</option>
                            <option value="widowed">widowed</option>
                            <option value="Divorced">Divorced</option>
                            <option value="Single">Single</option>
                            <option value="Seperated">Seperated</option>
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Marital Status
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}  {userData && userData.partner_matrialStatus || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}


                    {userData && (
                      <>
                        {isEditingPartner ? (
                          <select id="eating" name="eating" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.partner_eatingHabit}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                partner_eatingHabit: e.target.value,
                              });
                            }}>
                            <option key="" value="" disabled> Eating Habits:</option>

                            <option value="Vegetarian">Vegetarian</option>
                            <option value="Non vegetarian">Non vegetarian</option>
                            <option value="Eggetarian">Eggetarian</option>
                            <option value="Does not matter">Does not matter</option>
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Eating Habits
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}   {userData && userData.partner_eatingHabit || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}


                    {userData && (
                      <>
                        {isEditingPartner ? (
                          <select id="smoke" name="smoke" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.partner_smokingHabit}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                partner_smokingHabit: e.target.value,
                              });
                            }}>
                            <option key="" value="" disabled> Smoking Habits:</option>
                            <option value="Does not matter">Does not matter</option>
                            <option value="Never smokes">Never smokes</option>
                            <option value="Smoke occasionally">Smoke occasionally</option>

                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Smoking Habits
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.partner_smokingHabit || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}


                  </Form>
                </MDBCol>
                <MDBCol size='6' sm='6' className='partner-profileedit'>
                  <Form>
                    {userData && (
                      <>
                        {isEditingPartner ? (
                          <select
                            id="height_from"
                            name="height_from"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.partner_height_from}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                partner_height_from: e.target.value,
                              });
                            }}
                          >
                            <option key="" value="Height From" disabled>Height From</option>
                            {height.map(height => (
                              <option key={height.n_slno} value={`${height.n_cm} cm `}>
                                {`${height.n_cm} cm / ${height.n_feet}`}
                              </option>
                            ))}
                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Height From
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {basicDetails.partner_height_from}   cm
                            </div>
                          </div>

                        )}

                      </>)}


                    {userData && (
                      <>
                        {isEditingPartner ? (
                          <select
                            id="height_to"
                            name="height_to"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.partner_height_to}  // Initialize with basicDetails.height_id
                            onChange={(e) => {

                              setBasicDetails({
                                ...basicDetails,
                                partner_height_to: e.target.value,

                              });
                            }}
                          >
                            <option key="" value="" disabled>Height To</option>
                            {height.map(height => (
                              <option key={height.n_slno} value={`${height.n_cm} cm`}>
                                {`${height.n_cm} cm / ${height.n_feet}`}
                              </option>
                            ))}
                          </select>
                        ) : (


                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Height To
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {basicDetails.partner_height_to} cm
                            </div>
                          </div>
                        )}
                      </>)}



                    {userData && (
                      <>
                        {isEditingPartner ? (
                          <select id="drink" name="drink" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.partner_drinkingHabit}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                partner_drinkingHabit: e.target.value,
                              });
                            }}>
                            <option value="" disabled>Driking Habits:</option>
                            <option value="Does not matter" >Does not matter</option>
                            <option key="" value="Drinks socially" >Drinks socially</option>
                            <option key="" value="Never drinks" >Never drinks</option>

                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Driking Habits
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''} {userData && userData.partner_drinkingHabit || 'Not defined'}
                            </div>
                          </div>


                        )}
                      </>)}

                    {userData && (
                      <>
                        {isEditingPartner ? (
                          <select id="physicalStatus" name="physicalStatus" className='SelectBoxs-searchpage-profileedit' style={{ width: 350 }}
                            value={basicDetails.partner_physicalStatus}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                partner_physicalStatus: e.target.value,
                              });
                            }}>
                            <option value="Physical Status" disabled>Physical Status</option>
                            <option value="Does not matter">Does not matter</option>
                            <option value="Normal">Normal</option>
                            <option value="Slim">Slim</option>

                          </select>
                        ) : (

                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Physical Status
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}  {userData && userData.partner_physical_status || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>)}

                    {userData && (
                      <>
                        {isEditingPartner ? (
                          <select
                            id="partner_professional_Status"
                            name="partner_professional_Status"
                            className='SelectBoxs-searchpage-profileedit'
                            style={{ width: 350 }}
                            value={basicDetails.partner_professioanlStatus}
                            onChange={(e) => {
                              setBasicDetails({
                                ...basicDetails,
                                partner_professioanlStatus: e.target.value,

                              });

                            }}

                          >
                            <option key="" value="" disabled>professional Status</option>
                            {occupation.map(occupation => (
                              <option key={occupation.occid} value={occupation.occupation}>
                                {occupation.occupation}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <div className='annual-income' style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }} className="full-bolder">
                              Professional Status
                            </div>
                            <div style={{ flex: 1 }}>
                              :{''}  {basicDetails.occupation_id ? basicDetails.partner_professioanlStatus : userData.partner_professional_Status || 'Not defined'}
                            </div>
                          </div>

                        )}
                      </>
                    )}

                  </Form>

                </MDBCol>

              </MDBRow>



              {/* <Button href='/' className='searchButtons-profileedit' type="submit">
        Save
        </Button> */}
            </div>
          </MDBCol>





        </MDBRow>
      </Container>
      <Footerinner></Footerinner>

    </div>
  )
}

export default Profileedit
import { MDBCol } from 'mdb-react-ui-kit';
import React, { useState } from 'react'
import ChangePassword from './ChangePassword';
import { Button } from 'react-bootstrap';
import "../../style/privacy.css";
import DeleteAccount from './DeleteAccount';


function SettingSection() {
  const [selectedTab, setSelectedTab] = useState("Change Password");
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <MDBCol size="6" sm="9" className="full-list-matches">
      <p className="account-setting">Account settings</p>
      <hr></hr>

      <div className="matches-privacy">
        <Button
          type="button"
          className={`matches-buttons-privacy  ${selectedTab === "Change Password" && "matches-buttons-privacy-active"}`}
          onClick={() => handleTabClick("Change Password")}
        >
          Change Password
        </Button>
        <Button
          type="button"
          className={`matches-buttons-privacy  ${selectedTab === "Delete Account" && "matches-buttons-privacy-active"}`}
          onClick={() => handleTabClick("Delete Account")}
        >
          Delete Account
        </Button>
      </div>
        {selectedTab === "Change Password" && <ChangePassword />} 
        {selectedTab === "Delete Account" && <DeleteAccount />}
   
    </MDBCol>
  );
};

export default SettingSection;
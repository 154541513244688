import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import api from "../../../Api/ProfileApi";
import { useNavigate } from "react-router-dom";

const Habits = ({ userData }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken");
  const [habits, setHabits] = useState({
    eating_habit: "",
    smoking_habit: "",
    drinking_habit: "",
  });
  const [isEditingHabbits, setIsEditingHabbits] = useState(false);
  useEffect(() => {
    setHabits({
      eating_habit: userData?.eating_habit,
      smoking_habit: userData?.smoking_habit,
      drinking_habit: userData?.drinking_habit,
    });
  }, [userData]);
  const handleEditClickHabbits = () => {
    setIsEditingHabbits(true);
  };

  const handleSaveClickHabbits = async () => {
    // remove this if when adding validation
    const payload = {
      eating: habits?.eating_habit,
      drinking: habits?.drinking_habit,
      smoking: habits?.smoking_habit,
    };
    await api.updateHabits(payload, token).then((data) => {
      if (data.status) {
        setIsEditingHabbits(false);
      } else if (data.status === 401) {
        localStorage.clear();
        navigate("/login");
      }
    });
  };
  return (
    <MDBRow>
      <div className="user-description">
        <p className="profile-description">Habits</p>
        <div className="editing-religious">
          {isEditingHabbits ? (
            <button onClick={handleSaveClickHabbits}>Save</button>
          ) : (
            <button onClick={handleEditClickHabbits}>
              <MDBIcon far icon="edit" /> Edit
            </button>
          )}
        </div>
      </div>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingHabbits ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Eating Habits
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="eating"
                name="eating"
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                value={habits?.eating_habit}
                onChange={(e) => {
                  setHabits({
                    ...habits,
                    eating_habit: e.target.value,
                  });
                }}
              >
                <option key="" value="">
                  {" "}
                  Select
                </option>

                <option value="Vegetarian">Vegetarian</option>
                <option value="Non vegetarian">Non vegetarian</option>
                <option value="Eggetarian">Eggetarian</option>
               {/*  <option value="Does not matter">Does not matter</option> */}
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Eating Habits
            </div>
            <div style={{ flex: 1 }}>
              :{""} {habits?.eating_habit || "Not defined"}
            </div>
          </div>
        )}
        {isEditingHabbits ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Smoking Habits
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="smoke"
                name="smoke"
                className="SelectBoxs-searchpage-profileedit"
                style={{ width: 350 }}
                value={habits?.smoking_habit}
                onChange={(e) => {
                  setHabits({
                    ...habits,
                    smoking_habit: e.target.value,
                  });
                }}
              >
                <option key="" value="">
                  {" "}
                  Select
                </option>
              {/*   <option value="Does not matter">Does not matter</option> */}
                <option value="Never smokes">Never smokes</option>
               <option value="Smoke occasionally">Smoke occasionally</option> 
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Smoking Habits
            </div>
            <div style={{ flex: 1 }}>
              :{""} {habits?.smoking_habit || "Not defined"}
            </div>
          </div>
        )}
      </MDBCol>
      <MDBCol size="6" sm="6" className="partner-profileedit">
        {isEditingHabbits ? (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="">
              Driking Habits
            </div>
            :
            <div className="basic-profiles" style={{ flex: 1 }}>
              <select
                id="drink"
                name="drink"
                className="SelectBoxs-searchpage-profileedit"
                value={habits?.drinking_habit}
                onChange={(e) => {
                  setHabits({
                    ...habits,
                    drinking_habit: e.target.value,
                  });
                }}
              >
                <option value="">Select</option>
               {/*  <option value="Does not matter">Does not matter</option> */}
                <option value="Drinks occasionally">Drinks occasionally</option>
              <option value="Never drinks">Never drinks</option> 
              </select>
            </div>
          </div>
        ) : (
          <div className="annual-income" style={{ display: "flex" }}>
            <div style={{ flex: 1 }} className="full-bolder">
              Driking Habits
            </div>
            <div style={{ flex: 1 }}>
              :{""} {habits?.drinking_habit || "Not defined"}
            </div>
          </div>
        )}
      </MDBCol>
    </MDBRow>
  );
};

export default Habits;

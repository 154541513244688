import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import "../style/nva.css";

function Nva() {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div>
      <Navbar collapseOnSelect className='navbarnav' expand="lg" sticky='top' bg="" variant="dark">
        <Container className='logoContainer'>
          <a href='/'>
            <img src="/Images/panchami_logo.png" alt="My Image" className='logo' />
          </a>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className='navbar_itesms'>
              <Nav className='menubar'>
                <Nav.Link as={Link} to="/" exact="true" activeClassName="active-link" style={{ borderBottom: pathname === '/' ? '1px solid #F7AEE2' : 'none' ,   fontSize: '13px'}}>
                  HOME
                </Nav.Link>
                <Nav.Link as={Link} to="/packeges" activeClassName="active-link" style={{ borderBottom: pathname === '/packeges' ? '1px solid #F7AEE2' : 'none',   fontSize: '13px' }}>
                PACKAGES 
                </Nav.Link>
                <Nav.Link as={Link} to="/registration" activeClassName="active-link" style={{ borderBottom: pathname === '/registration' ? '1px solid #F7AEE2' : 'none',   fontSize: '13px' }}>
                FREE REGISTER
                </Nav.Link>
                <Nav.Link as={Link}  to="/search" activeClassName="active-link" style={{ borderBottom: pathname ===  "/search" ? '1px solid #F7AEE2' : 'none',   fontSize: '13px' }}>
                SEARCH
                </Nav.Link>
                <Nav.Link as={Link} to="/success-stories" activeClassName="active-link" style={{ borderBottom: pathname === '/success-stories' ? '1px solid #F7AEE2' : 'none',   fontSize: '13px' }}>
                SUCCESS STORIES
                </Nav.Link>
                <Nav.Link as={Link} to="/contact" activeClassName="active-link" style={{ borderBottom: pathname === '/contact' ? '1px solid #F7AEE2' : 'none',   fontSize: '13px' }}>
                  CONTACT US
                </Nav.Link>

               
                <div className='loginButton'>
                  <Button className='me-2 login-button' style={{   fontSize: '13px' }} as={Link} to="/login">
                    LOGIN
                  </Button>
                </div>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Nva;
